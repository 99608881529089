<script lang="ts" setup>
import JpgInstaStory from '@/assets/img/insta-story.jpg';
import SvgChameleon from '@/vue/atoms/svg-chameleon.vue';
import LazyImage from '@/vue/molecules/lazy-image.vue';
import {useUnlockStore} from '@/ts/store/unlock-store';
import {computed, ref, watchEffect} from 'vue';
import {storeToRefs} from 'pinia';
import {UnlockTransitionState} from '@/ts/types/component/questionnaire.type';
import {sleep} from '@/ts/utils/pure-functions';
import type {FeedSectionTypeDTO} from '@/ts/types/dto/feed.dto';
import type {TopicDTO} from '@/ts/types/dto/topic.dto';

const props = defineProps<{
  topic?: TopicDTO | null;
  section: FeedSectionTypeDTO | 'bookmarks';
  cardIndex: number;
  isLastInList: boolean;
}>();

const unlockStore = useUnlockStore();
const {unlockTransition} = storeToRefs(unlockStore);

const animationClass = ref<string>('');
const isUnlockingInProgress = computed((): boolean => {
  return Boolean(props.section) &&
    props.section === unlockTransition.value?.section &&
    unlockTransition.value?.state === UnlockTransitionState.UnlockingAnimationInProgress;
});

function showUnlockDialog(): void {
  if (isUnlockingInProgress.value) {
    return;
  }

  if (props.section && props.section !== 'bookmarks') {
    unlockStore.startInitialUnlockTransition(
      props.section as FeedSectionTypeDTO,
      props.topic,
    );
  }
}

const lockSvgMap = new Map<string, {id: string; viewBox: string}>([
  [
    'open',
    {
      id: 'lock-open',
      viewBox: '0 0 41 41',
    },
  ],
  [
    'close',
    {
      id: 'lock-closed',
      viewBox: '0 0 51 52',
    },
  ],
]);
const lockSvg = ref<{id: string; viewBox: string}>(lockSvgMap.get('close')!);

watchEffect((): void => {
  if (isUnlockingInProgress.value) {
    void (async(): Promise<void> => {
      const animationClasses: string[] = [];

      await sleep(300 * props.cardIndex + 700); // eslint-disable-line @typescript-eslint/no-magic-numbers
      animationClasses.push('animation-step-1');
      animationClass.value = animationClasses.join(' ');
      lockSvg.value = lockSvgMap.get('open')!;

      await sleep(1500); // eslint-disable-line @typescript-eslint/no-magic-numbers
      animationClasses.push('animation-step-2');
      animationClass.value = animationClasses.join(' ');

      await sleep(1000); // eslint-disable-line @typescript-eslint/no-magic-numbers
      animationClasses.push('animation-step-3');
      animationClass.value = animationClasses.join(' ');

      if (props.isLastInList) {
        await sleep(500); // eslint-disable-line @typescript-eslint/no-magic-numbers
        unlockStore.finishUnlockTransition();
      }
    })();
  } else {
    animationClass.value = '';
    lockSvg.value = lockSvgMap.get('close')!;
  }
});
</script>

<template>
  <div
    class="lock-overlay"
    :class="animationClass"
    :role="isUnlockingInProgress ? undefined : 'button'"
    :tabindex="isUnlockingInProgress ? -1 : 0"
    @keydown.enter="showUnlockDialog"
    @keydown.space="showUnlockDialog"
    @click.native="showUnlockDialog"
  >
    <div class="content">
      <div>
        <lazy-image
          :src="JpgInstaStory"
          class="img"
          loading="eager"
        />
      </div>

      <div class="middle-icon">
        <svg-chameleon
          :svg-symbol-id="lockSvg.id"
          :svg-view-box="lockSvg.viewBox"
          class="lock-icon"
          size="52px 52px"
        />
        <span class="text-h3">{{ $t('recco_dashboard_unlock') }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.img {
  width: $carousel-card-width;
  height: $carousel-card-height;
}

.middle-icon {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--primary-color-primary);
  gap: var(--S);

  [role="button"]:focus &,
  &:hover {
    text-decoration: underline;
  }
}

.lock-overlay {
  @include focus-visible;
  border-radius: var(--S);
  background-color: var(--color-white); // hardcoded white as long we work with a white pixel-image as locked-cover
  transition: opacity 400ms linear;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  &[role="button"] {
    cursor: pointer;
  }
}

.lock-icon {
  transition: transform 400ms ease-out;
}

.content {
  transition: opacity 500ms linear;
  border-radius: var(--S);
  overflow: hidden;
}

.animation {

  &-step-1 {
    .lock-icon {
      transform: rotate(-16.613deg);
    }
  }

  &-step-2 {
    .content {
      opacity: 0;
    }
  }

  &-step-3 {
    &.lock-overlay {
      opacity: 0;
    }
  }
}

</style>
