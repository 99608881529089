/* eslint-disable sonarjs/no-duplicate-string */
import type {
  NavigationGuardNext,
  RouteLocation,
  RouteLocationNormalized,
  RouteLocationRaw,
  RouteRecordRaw,
} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import QuestionnairePage from '@/vue/pages/unlock/questionnaire-page.vue';
import {getMetaPageTitle} from '@/ts/router/router-factory';
import {
  questionnairePathSlugsForSections,
  questionnairePathSlugsForCategories,
  questionnairePathSlugsForTypes,
} from '@/ts/types/component/questionnaire.type';

export const unlockQuestionnairePages: RouteRecordRaw[] = [
  {
    name: pagesPath.unlock.questionnaireEntry,
    path: pagesPath.unlock.questionnaireEntry,
    redirect: (to: RouteLocation): RouteLocationRaw => {
      return {
        name: pagesPath.unlock.questionnaireQuestion,
        params: {
          questionCounter: '1',
          id: to.params.id,
          category: to.params.category,
          type: to.params.type,
          section: to.params.section,
        },
      };
    },
  },
  {
    name: pagesPath.unlock.questionnaireQuestion,
    path: pagesPath.unlock.questionnaireQuestion,
    component: QuestionnairePage,
    meta: {
      ...getMetaPageTitle('recco_page_title_questionnaire', true),
    },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
      const allowedCategorySlugs = Object.values(questionnairePathSlugsForCategories);
      const allowedSectionSlugs = ['', ...Object.values(questionnairePathSlugsForSections)];
      const allowedTypeSlugs = Object.values(questionnairePathSlugsForTypes);

      if (
        allowedCategorySlugs.includes(to.params.category as string) &&
        allowedSectionSlugs.includes((to.params.section ?? '') as string) &&
        allowedTypeSlugs.includes(to.params.type as string)
      ) {
        next();
      } else {
        next({
          path: '/questionnaire-not-found', // doesn't exist, leads to 404-page
          replace: false,
        });
      }
    },
  },
];
