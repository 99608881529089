<script lang="ts" setup>

import {computed} from 'vue';

const props = defineProps<{
  text: string;
}>();

const lines = computed(() => props.text.split('\n').filter((line) => line.trim()));

</script>
<template>
  <p v-for="(line, i) in lines" :key="i">
    {{ line }}
  </p>
</template>

