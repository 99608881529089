/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  createI18n,
  type I18n,
} from 'vue-i18n';

// these imports are generated by i18-bundle-plugin.ts
import en from '@/locales/en.json';
import de from '@/locales/de.json';


export function i18nFactory(): I18n {
  return createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'en',
    messages: {
      en: en.translations,
      de: de.translations,
    },
    datetimeFormats: {
      en: en.datetimeFormats,
      de: de.datetimeFormats,
    },
    numberFormats: {
      en: en.numberFormats,
      de: de.numberFormats,
    },
  });
}
