import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  type Router,
  type RouteRecordRaw,
  type RouterHistory,
} from 'vue-router';
import NotFoundPage from '@/vue/pages/not-found-page.vue';
import {mainPages} from '@/ts/router/pages/main-pages';
import {authPages} from '@/ts/router/pages/auth-pages';
import {devPages} from '@/ts/router/pages/dev-pages';
import {
  DEV_PAGES,
  ROUTER_BASE_URL,
} from '@/ts/utils/consts';
import type {RouteMetaPageTitle} from '@/ts/types/component/page.type';
import {usePageTitle} from '@/ts/composables/use-page-title';

export function getMetaPageTitle(titleLanguageKey: string, deferTitleDetermination: boolean = false): RouteMetaPageTitle {
  return {
    titleLanguageKey,
    deferTitleDetermination,
  };
}

export function routerFactory(): Router {
    const routes: RouteRecordRaw[] = [
    mainPages,
    authPages,
    {
      path: '/:catchAll(.*)',
      component: NotFoundPage,
      meta: {
        ...getMetaPageTitle('recco_page_title_404'),
      },
    },
  ];
  if (DEV_PAGES) {
    routes.push(...devPages);
  }

  const history: RouterHistory = ROUTER_BASE_URL ? createWebHashHistory(ROUTER_BASE_URL) : createWebHistory();
  const router = createRouter({
    history,
    routes,
  });

  router.afterEach((to) => {
    const meta = to.meta as RouteMetaPageTitle;
    const {updatePageTitle, displayLoadingPageTitle} = usePageTitle();
    if (meta.deferTitleDetermination) {
      displayLoadingPageTitle();
    } else {
      updatePageTitle();
    }
  });

  return router;
}
