<script lang="ts" setup>
import {computed} from 'vue';
import QuestionMain from '@/vue/molecules/questionnaire/question-main.vue';
import {useQuestionnaireStore} from '@/ts/store/questionnaire-store';
import type {
  QuestionnaireAnswer,
  QuestionnaireQuestion,
  Questionnaire,
} from '@/ts/types/component/questionnaire.type';
import QuestionHeader from '@/vue/molecules/questionnaire/question-header.vue';
import {useQuestionnaireRouting} from '@/ts/composables/use-questionnaire-routing';
import type {CreateQuestionnaireAnswerDTO} from '@/ts/types/dto/questionnaire.dto';

const props = defineProps<{
  title: string;
  questionnaire: Questionnaire;
  answers: QuestionnaireAnswer[];
  submittingInProgress: boolean;
}>();

const {questionCounter: defaultQuestionCounter, navigateToQuestion} = useQuestionnaireRouting();

const emit = defineEmits<{
  submitAnswers: [values: CreateQuestionnaireAnswerDTO[]];
}>();

const questionnaireStore = useQuestionnaireStore();

const questionCounter = computed((): number => {
  return Math.min(Math.max(defaultQuestionCounter.value, 1), props.questionnaire.questions.length);
});

const currentQuestion = computed((): QuestionnaireQuestion => {
  return props.questionnaire.questions[questionCounter.value - 1];
});

const currentAnswer = computed((): QuestionnaireAnswer | null => {
  const existingAnswer = props.answers.find((answer) => {
    return answer.questionId === currentQuestion.value.id &&
      answer.questionnaireId === currentQuestion.value.questionnaireId;
  });

  return existingAnswer ?? null;
});

const onAnswerGiven = (answer: QuestionnaireAnswer): void => {
  questionnaireStore.applyAnswer(props.questionnaire.id, answer);
  const isLastQuestion = questionCounter.value === props.questionnaire.questions.length;
  const allQuestionsAnswered = props.answers.length === props.questionnaire.questions.length;
  if (isLastQuestion && allQuestionsAnswered) {
    emit('submitAnswers', questionnaireStore.toTransferObjects(props.questionnaire.id));
  } else {
    const answeredQuestionIds = props.answers.map((answerItem) => answerItem.questionId);
    // handle edge-case: user changed number in URL and has stared not with the first question
    const firstQuestionIndexNotAnswered = props.questionnaire.questions.findIndex((question) => !answeredQuestionIds.includes(question.id));
    let nextIndex = questionCounter.value;
    if (firstQuestionIndexNotAnswered > -1) {
      nextIndex = Math.min(firstQuestionIndexNotAnswered, nextIndex);
    }
    navigateToQuestion(nextIndex + 1);
  }
};

</script>

<template>
  <div>
    <question-header
      :title="title"
      :current-question-counter="questionCounter"
      :total-num-of-questions="questionnaire.questions.length"
    />

    <question-main
      :question="currentQuestion"
      :answer-preset="currentAnswer"
      :submitting-in-progress="submittingInProgress"
      :is-last-question="questionCounter === questionnaire.questions.length"
      @answer-given="onAnswerGiven"
      @navigate-back="navigateToQuestion(Math.max(currentQuestion.questionCounter - 1, 1))"
    />
  </div>
</template>
