<script lang="ts" setup>
import {computed} from 'vue';
import {pagesPath} from '@/ts/router/pages-path';
import type {RecCard} from '@/ts/types/component/recs.type';
import RouterLinkWithOriginQuery from '@/vue/atoms/router-link-with-origin-query.vue';
import LazyImage from '@/vue/molecules/lazy-image.vue';
import {CARD_HEIGHT, CARD_WIDTH} from '@/ts/utils/css-consts';
import MediaTypeAndDuration from '@/vue/molecules/media-type-and-duration.vue';
import type {RouteLocationNamedRaw} from 'vue-router';
import {RecommendationTypeDTO} from '@/ts/types/dto/recommendation.dto';

const props = defineProps<{
  cardData: RecCard;
}>();

const idAria = computed(() => `${props.cardData.section ?? 'recommendation'}_${props.cardData.recommendation?.id.catalogId}`);

const recLink = computed((): RouteLocationNamedRaw|null => {
  const routeName = {
    [RecommendationTypeDTO.Article]: pagesPath.main.article,
    [RecommendationTypeDTO.Audio]: pagesPath.main.audio,
    [RecommendationTypeDTO.Video]: pagesPath.main.video,
  }[props.cardData.recommendation?.type?.toString() ?? ''];

  if (!routeName || !props.cardData.recommendation?.id) {
    return null;
  }

  return {
    name: routeName,
    params: {
      itemId: props.cardData.recommendation.id.itemId,
      catalogId: props.cardData.recommendation.id.catalogId,
    },
  };
});

</script>

<template>
  <router-link-with-origin-query
    :to="recLink"
    class="link"
    :aria-labelledby="idAria"
  >
    <q-card
      flat
      class="cursor-pointer q-card"
    >
      <q-card-section v-if="cardData.recommendation" class="card-section">
        <lazy-image
          :width="CARD_WIDTH"
          :height="CARD_HEIGHT"
          format="webp"
          :src="cardData.recommendation.dynamicImageResizingUrl"
          class="img"
          loading="lazy"
          :alt="cardData.recommendation.imageAlt"
        />

        <div class="title-box">
          <span :id="idAria" class="text-body1 title">
            {{ cardData.recommendation.headline }}
          </span>

          <media-type-and-duration class="media-type" :type="cardData.recommendation.type" size="s"/>
        </div>
      </q-card-section>
    </q-card>
  </router-link-with-origin-query>
</template>

<style lang="scss" scoped>

.q-card {
  width: $carousel-card-width;
  min-height: $carousel-card-height;
  border-radius: var(--S);
  padding-bottom: var(--S);
}

.card-section {
  gap: 8px;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.link {
  display: block;
  text-decoration: none;
  color: var(--primary-color-primary-80);
  text-decoration-color: var(--primary-color-primary-80);
  min-height: 100%;
  border-radius: var(--S);

  &:hover .title,
  &:focus .title {
    text-decoration: underline;
    text-decoration-color: inherit;
  }
}

.img {
  width: $carousel-card-width;
  height: $carousel-card-height;
  border-radius: var(--S);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  :deep(.q-img__image) {
    transition: all .3s linear;

    .link:hover &,
    .link:focus & {
      transform: scale(1.05);
    }
  }
}

.title-box {
  padding: var(--XXS) var(--XS);
}

.title {
  margin-bottom: var(--XXS);
  font-weight: 600;
  color: var(--primary-color-primary-80);
  padding-bottom: 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // 96% of modern browser support
}

</style>
