<script lang="ts" setup>

import {computed} from 'vue';
import {QuestionAnswerType, type MultiChoiceQuestionDTO, type NumericQuestionDTO} from '@/ts/types/dto/questionnaire.dto';
import type {QuestionnaireQuestion} from '@/ts/types/component/questionnaire.type';
import QuestionAnswerSelection from '@/vue/molecules/questionnaire/question-answer-selection.vue';
import QuestionAnswerNumeric from '@/vue/molecules/questionnaire/question-answer-numeric.vue';

const props = defineProps<{
  question: QuestionnaireQuestion;
  labeledBy: string;
}>();

const selection = defineModel<number[]>('selection', {required: true});
const numericInput = defineModel<number>('numericInput', {required: true});
const isInputValid = defineModel<boolean>('isInputValid', {required: true});

const emit = defineEmits<{
  enterInput: [];
}>();

const dataChoices = computed((): MultiChoiceQuestionDTO | null => {
  const isTypeChoices = props.question.type === QuestionAnswerType.MultiChoice && props.question.multiChoice;
  return isTypeChoices ? props.question.multiChoice! : null;
});

const dataNumeric = computed((): NumericQuestionDTO | null => {
  const isTypeNumeric = props.question.type === QuestionAnswerType.Numeric && props.question.numeric;
  return isTypeNumeric ? props.question.numeric! : null;
});

</script>

<template>
  <div v-if="dataChoices">
    <div v-if="dataChoices.maxOptions > 1" class="text-center text-body2 text-primary-4">
      {{ $t('recco_questionnaire_multiple_answers') }}
    </div>
    <question-answer-selection
      v-model="selection"
      v-model:is-input-valid="isInputValid"
      :choices="dataChoices"
      class="q-mt-xl q-mb-xl"
      :labeled-by="labeledBy"
    />
  </div>

  <question-answer-numeric
    v-else-if="dataNumeric"
    v-model="numericInput"
    v-model:is-input-valid="isInputValid"
    :range-of-validity="dataNumeric"
    :labeled-by="labeledBy"
    class="q-mt-xl q-mb-xl"
    @enterInput="emit('enterInput')"
  />
</template>
