export interface Unit {
  unit: LengthUnit | WeightUnit;
  displayAs: string;
}

export enum LengthUnit {
  Centimeter = 'cm',
  Foot = 'ft',
  Inch = 'in',
}

export enum WeightUnit {
  Kilogram = 'kg',
  Stone = 'st',
  Pound = 'lbs',
}
