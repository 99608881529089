import {pagesPath} from '@/ts/router/pages-path';
import type {RouteRecordRaw} from 'vue-router';
import BasePage from '@/vue/pages/base-page.vue';
import HomePage from '@/vue/pages/recommendations/home-page.vue';
import {unlockQuestionnairePages} from '@/ts/router/pages/unlock-pages';
import {onboardPages} from '@/ts/router/pages/onboard-pages';
import ArticlePage from '@/vue/pages/recommendations/article-page.vue';
import AudioPage from '@/vue/pages/recommendations/audio-page.vue';
import VideoPage from '@/vue/pages/recommendations/video-page.vue';
import BookmarksPage from '@/vue/pages/recommendations/bookmarks-page.vue';
import {getMetaPageTitle} from '@/ts/router/router-factory';

export const mainPages: RouteRecordRaw = {
  path: pagesPath.main.home,
  component: BasePage,
  children: [
    {
      path: pagesPath.main.home,
      component: HomePage,
      meta: {
        ...getMetaPageTitle('recco_page_title_home', true),
      },
    },
    {
      path: pagesPath.main.bookmarks,
      component: BookmarksPage,
      meta: {
        ...getMetaPageTitle('recco_page_title_bookmarks'),
      },
    },
    {
      path: pagesPath.main.article,
      name: pagesPath.main.article,
      component: ArticlePage,
      meta: {
        ...getMetaPageTitle('recco_page_title_recommendation', true),
      },
    },
    {
      path: pagesPath.main.audio,
      name: pagesPath.main.audio,
      component: AudioPage,
      meta: {
        ...getMetaPageTitle('recco_page_title_recommendation', true),
      },
    },
    {
      path: pagesPath.main.video,
      name: pagesPath.main.video,
      component: VideoPage,
      meta: {
        ...getMetaPageTitle('recco_page_title_recommendation', true),
      },
    },
    onboardPages,
    ...unlockQuestionnairePages,
  ],
};
