
import {ref, type Ref} from 'vue';
import {convertLength, convertWeight} from '@/ts/utils/pure-functions';
import {useI18n} from 'vue-i18n';
import {NumericQuestionFormat} from '@/ts/types/dto/questionnaire.dto';
import {type Unit, LengthUnit, WeightUnit} from '@/ts/types/component/units-type';
import {usePageStore} from '@/ts/store/page-store';

const weightUnitsByCountry = new Map<string, WeightUnit>([
  ['US', WeightUnit.Pound],
  ['GB', WeightUnit.Stone],
  ['IE', WeightUnit.Stone],
]);

const lengthUnitsByCountry = new Map<string, LengthUnit>([
  ['US', LengthUnit.Inch],
  ['GB', LengthUnit.Inch],
  ['IE', LengthUnit.Inch],
]);

const langToken: Record<LengthUnit | WeightUnit, string> = {
  [LengthUnit.Centimeter]: 'recco_questionnaire_numeric_label_cm',
  [LengthUnit.Foot]: 'recco_questionnaire_numeric_label_ft',
  [LengthUnit.Inch]: 'recco_questionnaire_numeric_label_in',
  [WeightUnit.Kilogram]: 'recco_questionnaire_numeric_label_kg',
  [WeightUnit.Pound]: 'recco_questionnaire_numeric_label_lb',
  [WeightUnit.Stone]: 'recco_questionnaire_numeric_label_st',
};

/* eslint max-lines-per-function: 0*/
export function useUnits(): {
  preferredLengthUnit: Ref<Unit>;
  preferredWeightUnit: Ref<Unit>;
  convertDisplayToStorage(value: number, format: NumericQuestionFormat): number;
  convertStorageToDisplay(value: number, format: NumericQuestionFormat): number;
} {
  const {t: $t} = useI18n();

  const pageStoreStore = usePageStore();
  const region = pageStoreStore.localization?.region ?? '';
  const lengthUnit = lengthUnitsByCountry.get(region) ?? LengthUnit.Centimeter; // Default to "cm", if not specified
  const weightUnit = weightUnitsByCountry.get(region) ?? WeightUnit.Kilogram; // Default to "kg", if not specified

  // TODO consider to keep preferred units in a store, in that way we could offer "settings" to the user.
  const preferredWeightUnit = ref<Unit>({
    unit: weightUnit,
    displayAs: $t(langToken[weightUnit]) ?? '',
  });

  const preferredLengthUnit = ref<Unit>({
    unit: lengthUnit,
    displayAs: $t(langToken[lengthUnit]) ?? '',
  });

  const convertWeightStorageToDisplay = (value: number): number => {
    return convertWeight(value, WeightUnit.Kilogram, preferredWeightUnit.value.unit as WeightUnit);
  };

  const convertWeightDisplayToStorage = (value: number): number => {
    return convertWeight(value, preferredWeightUnit.value.unit as WeightUnit, WeightUnit.Kilogram);
  };

  const convertLengthStorageToDisplay = (value: number): number => {
    return convertLength(value, LengthUnit.Centimeter, preferredLengthUnit.value.unit as LengthUnit);
  };

  const convertLengthDisplayToStorage = (value: number): number => {
    return convertLength(value, preferredLengthUnit.value.unit as LengthUnit, LengthUnit.Centimeter);
  };

  const convertStorageToDisplay = (value: number, format: NumericQuestionFormat): number => {
    let convertedVal = value;
    if (format === NumericQuestionFormat.HumanWeight) {
      convertedVal = convertWeightStorageToDisplay(value);
    } else if (format === NumericQuestionFormat.HumanHeight) {
      convertedVal = convertLengthStorageToDisplay(value);
    }
    return convertedVal;
  };

  const convertDisplayToStorage = (value: number, format: NumericQuestionFormat): number => {
    let convertedVal = value;
    if (format === NumericQuestionFormat.HumanWeight) {
      convertedVal = convertWeightDisplayToStorage(value);
    } else if (format === NumericQuestionFormat.HumanHeight) {
      convertedVal = convertLengthDisplayToStorage(value);
    }
    return convertedVal;
  };

  return {
    preferredWeightUnit,
    preferredLengthUnit,
    convertStorageToDisplay,
    convertDisplayToStorage,
  };
}
