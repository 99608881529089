<script setup lang="ts">
import {NumericQuestionFormat, type NumericQuestionDTO} from '@/ts/types/dto/questionnaire.dto';
import {computed, defineModel, watchEffect} from 'vue';
import {useUnits} from '@/ts/composables/use-units';
import {getRandomId} from '@/ts/utils/pure-functions';

const props = defineProps<{
  rangeOfValidity: NumericQuestionDTO;
  labeledBy: string;
}>();

const modelValue = defineModel<number>({required: true});
const isInputValid = defineModel<boolean>('isInputValid', {required: true});

const emit = defineEmits<{
  enterInput: [];
}>();

watchEffect(() => {
  const minVal = props.rangeOfValidity.minValue ?? 0; // exclude negative numbers
  const hasMaxVal = typeof props.rangeOfValidity.maxValue === 'number' && !Number.isNaN(props.rangeOfValidity.maxValue);
  const val = modelValue.value;
  const validMinVal = minVal <= val;
  const validMaxVal = !hasMaxVal || props.rangeOfValidity.maxValue >= val;
  isInputValid.value = validMinVal && validMaxVal;
});

const {convertStorageToDisplay, convertDisplayToStorage, preferredLengthUnit, preferredWeightUnit} = useUnits();

const displayModelValue = computed((): number => {
  let valueToDisplay = convertStorageToDisplay(modelValue.value, props.rangeOfValidity.format);

  if (props.rangeOfValidity.format === NumericQuestionFormat.HumanHeight ||
    props.rangeOfValidity.format === NumericQuestionFormat.HumanWeight) {
    // limit decimal places to two
    // do it, because some conversions of units lead to minor round off errors.
    // in that way we hide these errors to the user
    valueToDisplay = Number(valueToDisplay.toFixed(2));
  }

  return valueToDisplay;
});

const displayUnit = computed((): string => {
  switch (props.rangeOfValidity.format) {
    case NumericQuestionFormat.HumanWeight:
      return preferredWeightUnit.value.displayAs;
    case NumericQuestionFormat.HumanHeight:
      return preferredLengthUnit.value.displayAs;
    default:
      return '';
  }
});

const labeledByUnitId = computed((): string => {
  return displayUnit.value ? `unit-${getRandomId()}` : '';
});

const onUpdateInput = (val: number | string | null): void => {
  const forceInt = props.rangeOfValidity.format === NumericQuestionFormat.Integer;

  let valNum: number = NaN;
  if (typeof val === 'string') {
    valNum = forceInt ? parseInt(val, 10) : parseFloat(val);
  } else if (typeof val === 'number') {
    valNum = forceInt ? Math.floor(val) : val;
  }

  valNum = Number.isNaN(valNum) ? 0 : valNum;
  modelValue.value = convertDisplayToStorage(valNum, props.rangeOfValidity.format);
};

</script>

<template>
  <div class="wrapper">
    <q-input
      input-class="text-body2"
      :model-value="displayModelValue"
      type="number"
      :aria-labelledby="`${labeledBy} ${labeledByUnitId}`.trim()"
      @update:model-value="onUpdateInput"
      @keyup.enter="emit('enterInput')"
    >
      <template #append>
        <span v-if="displayUnit" :id="labeledByUnitId" class="text-body2 text-primary-8">{{ displayUnit }}</span>
      </template>
    </q-input>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  max-width: 444px;
  margin-left: auto;
  margin-right: auto;
}

</style>
