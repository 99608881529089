import {
  onBeforeUnmount,
  onMounted,
  ref,
  type Ref,
} from 'vue';

export function useMediaWidth(): Ref<number> {
  const windowWidth = ref<number>(window.innerWidth);

  function updateWindowWidth(): void {
    windowWidth.value = window.innerWidth;
  }

  onMounted(() => {
    updateWindowWidth();
    window.addEventListener('resize', updateWindowWidth);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateWindowWidth);
  });
  return windowWidth;
}
