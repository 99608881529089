<script lang="ts" setup>
import {computed, defineModel, ref, watchEffect} from 'vue';
import type {QBtn} from 'quasar';
import MediaControlButton from '@/vue/atoms/media-control-button.vue';
import {MediaType} from '@/ts/types/component/media-player.type';

const currentTime = defineModel<number>('currentTime', {required: true});

const props = defineProps<{
  isPlaying: boolean,
  duration: number,
  mediaType: MediaType,
}>();

const emit = defineEmits<{
  clickPlay: [],
  clickPause: [],
  clickFullscreen: [],
}>();

const formattedCurrentTime = computed((): string => {
  const min = Math.floor(currentTime.value / 60); //eslint-disable-line @typescript-eslint/no-magic-numbers
  const sec = Math.round(currentTime.value % 60); //eslint-disable-line @typescript-eslint/no-magic-numbers
  return `${min}:${sec.toString().padStart(2, '0')}`;
});

const formattedDuration = computed((): string => {
  const min = Math.floor(props.duration / 60); //eslint-disable-line @typescript-eslint/no-magic-numbers
  const sec = Math.round(props.duration % 60); //eslint-disable-line @typescript-eslint/no-magic-numbers
  return `${min}:${sec.toString().padStart(2, '0')}`;
});

const displayCenteredControls = computed((): boolean => {
  return props.mediaType === MediaType.Audio || !props.isPlaying;
});

const playBtnEl = ref<QBtn>();
const pauseBtnEl1 = ref<QBtn>();
const pauseBtnEl2 = ref<QBtn>();

watchEffect(() => {
  if (props.isPlaying) {
    // only on of them should exist
    (pauseBtnEl1.value?.$el as HTMLElement)?.focus();
    (pauseBtnEl2.value?.$el as HTMLElement)?.focus();
  } else {
    (playBtnEl.value?.$el as HTMLElement)?.focus();
  }
});

const onClickLittleBack = (): void => {
  //eslint-disable-next-line @typescript-eslint/no-magic-numbers
  currentTime.value = Math.max(currentTime.value - 10, 0);
};

const onClickLittleForward = (): void => {
  //eslint-disable-next-line @typescript-eslint/no-magic-numbers
  currentTime.value = Math.min(currentTime.value + 10, props.duration);
};

</script>

<template>
  <div class="media-controls-overlay">
    <q-space/>

    <div v-if="displayCenteredControls" class="centered-controls">
      <media-control-button
        v-if="!isPlaying"
        ref="playBtnEl"
        type="play"
        @click="emit('clickPlay')"
      />
      <media-control-button
        v-if="isPlaying"
        ref="pauseBtnEl1"
        type="pause"
        @click="emit('clickPause')"
      />
      <media-control-button
        display-flat
        type="skipBackward"
        class="order-first"
        @click="onClickLittleBack"
      />
      <media-control-button
        display-flat
        type="skipForward"
        class="order-last"
        @click="onClickLittleForward"
      />
    </div>

    <div>
      <div class="button-bar">
        <div class="button-bar__group">
          <media-control-button
            v-if="mediaType === MediaType.Video && isPlaying"
            ref="pauseBtnEl2"
            display-flat
            type="pause"
            @click="emit('clickPause')"
          />
        </div>
        <div class="button-bar__group">
          <media-control-button
            v-if="!displayCenteredControls"
            display-flat
            type="skipBackward"
            @click="onClickLittleBack"
          />
          <media-control-button
            v-if="!displayCenteredControls"
            display-flat
            type="skipForward"
            @click="onClickLittleForward"
          />
          <media-control-button
            display-flat
            type="fullscreen"
            @click="emit('clickFullscreen')"
          />
        </div>
      </div>

      <q-slider
        v-model="currentTime"
        :max="duration"
        color="white"
      />

      <div class="duration">
        <span class="text-white text-overline">{{ formattedCurrentTime }}</span>
        <span class="text-white text-overline">{{ formattedDuration }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.media-controls-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 56px;
  bottom: 50px;
  top: 10px;
  right: 56px;

  @media (max-width: $breakpoint-sm-max) {
    bottom: 10px;
  }
}

.button-bar {
  display: flex;
  justify-content: space-between;
  margin: 0 -7px;

  &__group {
    display: flex;
    gap: 5px;
  }
}

.centered-controls {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.duration {
  display: flex;
  justify-content: space-between;
  @media (max-width: $breakpoint-xs-max) {
    display: none;
  }
}

.q-space {
  max-height: 100px;
}

</style>