<script setup lang="ts">

import type {MultiChoiceQuestionDTO} from '@/ts/types/dto/questionnaire.dto';
import AriaListBox from '@/vue/molecules/aria-list-box.vue';
import {watchEffect} from 'vue';
import QuestionAnswerSelectionItem from '@/vue/molecules/questionnaire/question-answer-selection-item.vue';

const props = defineProps<{
  choices: MultiChoiceQuestionDTO;
  labeledBy: string;
}>();

const modelValue = defineModel<number[]>({required: true});
const isInputValid = defineModel<boolean>('isInputValid', {required: true});

watchEffect(() => {
  const numberOfAnswers = modelValue.value.length;
  const min = props.choices.minOptions ?? 0;
  const max = props.choices.maxOptions ?? props.choices.options.length;
  isInputValid.value = min <= numberOfAnswers && numberOfAnswers <= max;
});

</script>

<template>
  <aria-list-box
    v-model="modelValue"
    :options="choices.options"
    :aria-labelledby="labeledBy"
    :maximum-selectable="props.choices.maxOptions"
    orientation="vertical"
  >
    <template #item="templateProps">
      <question-answer-selection-item
        :option="templateProps.option"
        :is-selected="templateProps.isSelected"
        :is-multiselect-mode="choices.maxOptions > 1"
      />
    </template>
  </aria-list-box>
</template>

<style lang="scss" scoped>

.listbox {
  max-width: 658px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &:focus-visible:deep(.listbox__option--active) {
    outline: 2px solid var(--accent-color-accent);
    border-radius: $generic-border-radius;
  }
}

</style>
