import {defineStore} from 'pinia';
import {ref} from 'vue';
import {useUserStore} from '@/ts/store/user-store';
import {sessionStore} from '@/ts/instances/session-instance';

export const useWarningStore = defineStore('warning-store', () => {
  const userStore = useUserStore();
  const {confirmedWarnings: storedConfirmedWarnings} = sessionStore.getConfirmedWarningsByUserId(userStore.user?.id ?? 'unknown');
  const confirmedWarnings = ref<string[]>(storedConfirmedWarnings);

  const confirmWarning = (warning: string): void => {
    if (!confirmedWarnings.value.includes(warning)) {
      confirmedWarnings.value.push(warning);
      if (userStore.user?.id) {
        sessionStore.confirmedWarnings = {
          userId: userStore.user.id,
          confirmedWarnings: confirmedWarnings.value,
        };
      }
    }
  };

  return {
    confirmedWarnings,
    confirmWarning,
  };
});