<script lang="ts" setup>

import Hls from 'hls.js';
import {onMounted, ref} from 'vue';

const props = defineProps<{
  hlsSrc: string;
}>();

const audioEl = ref<HTMLMediaElement>();

onMounted(() => {
  if (Hls.isSupported()) {
    const hls = new Hls();
    hls.loadSource(props.hlsSrc);
    hls.attachMedia(audioEl.value!);
  } else if (audioEl.value!.canPlayType('application/vnd.apple.mpegurl')) {
    audioEl.value!.src = props.hlsSrc;
  }
});

</script>

<template>
  <div>
    <audio ref="audioEl" class="audio-player" controls/>
  </div>
</template>

<style lang="scss" scoped>

.audio-player {
  width: 100%;
}

</style>