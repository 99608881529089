import type {RouteRecordRaw} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import Unauthorized401Page from '@/vue/pages/unauthorized-401-page.vue';
import {getMetaPageTitle} from '@/ts/router/router-factory';

export const authPages: RouteRecordRaw = {
  path: pagesPath.auth.signIn,
  component: Unauthorized401Page,
  meta: {
    ...getMetaPageTitle('recco_page_title_401'),
  },
};
