<script lang="ts" setup async>
import {useApi} from '@/ts/composables/use-api';
import type {
  CreateQuestionnaireAnswerDTO,
} from '@/ts/types/dto/questionnaire.dto';
import {useLoadingNotification} from '@/ts/composables/use-loading';
import type {
  Questionnaire,
  QuestionnaireAnswer,
} from '@/ts/types/component/questionnaire.type';
import {useQuestionnaireStore} from '@/ts/store/questionnaire-store';
import {storeToRefs} from 'pinia';
import {useRouter} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import {computed} from 'vue';
import QuestionnaireWidget from '@/vue/organisms/questionnaire-widget.vue';

const questionnaireStore = useQuestionnaireStore();
const {getAnswersByQuestionnaireId} = storeToRefs(questionnaireStore);
const router = useRouter();

const api = useApi();
const questionnaire: Questionnaire = await api.questionnaire.getQuestionnaire('onboard');
questionnaireStore.registerQuestionnaire(questionnaire.id);
const answers = computed((): QuestionnaireAnswer[] => getAnswersByQuestionnaireId.value(questionnaire.id));

const {submitHandler, loading} = useLoadingNotification(
  async(values: CreateQuestionnaireAnswerDTO[]) => api.questionnaire.postOnboardingAnswers(values),
  (): void => {
    void router.push(pagesPath.onboard.anamnesisCompleted);
  },
);

</script>

<template>
  <div class="page-root-node">
    <questionnaire-widget
      v-if="questionnaire.questions.length"
      :title="$t('recco_questionnaire_about_you')"
      :questionnaire="questionnaire"
      :answers="answers"
      :submitting-in-progress="loading"
      @submitAnswers="submitHandler"
    />
  </div>
</template>
