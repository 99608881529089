<script setup lang="ts">
import {useSlots} from 'vue';
import LoaderAnimation from '@/vue/molecules/loader-animation.vue';
import ErrorBox from '@/vue/molecules/error-box.vue';

const slots = useSlots();
defineProps<{
  error?: string | null;
  loading?: boolean;
}>();

defineSlots<{
  loader?(): any;
  error?(): any;
  default(): any;
}>();
</script>
<template>
  <slot v-if="error && slots.error" name="error"/>
  <error-box v-else-if="error" :error="error"/>
  <template v-else-if="loading">
    <slot v-if="slots.loader" name="loader"/>
    <loader-animation v-else/>
  </template>
  <slot v-else name="default"/>
</template>

