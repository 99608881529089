import type {IdDTO, RecommendationTypeDTO} from '@/ts/types/dto/recommendation.dto';

// Backend enum Rating
export enum RatingDTO {
  LIKE = 'like',
  DISLIKE = 'dislike',
  NOT_RATED = 'not_rated',
}

export interface PutRatingRequest {
  contentId: IdDTO;
  contentType: RecommendationTypeDTO;
  rating: RatingDTO;
}
