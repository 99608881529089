<script lang="ts" setup>
import IconButton from '@/vue/atoms/icon-button.vue';
import {RatingDTO} from '@/ts/types/dto/rating.dto';
import {ColorType} from '@/ts/types/component/color.type';

defineProps<{
  loadingBookmarks: boolean;
  loadingLike: boolean;
  bookmarked: boolean;
  rating: RatingDTO;
}>();

const emit = defineEmits<{
  like: [];
  dislike: [];
  bookmark: [];
}>();
</script>
<template>
  <div class="div">
    <icon-button
      label="Bookmark"
      :svg-symbol-id="bookmarked ? 'bookmark-active' : 'bookmark'"
      size="24px 24px"
      flat
      :aria-pressed="bookmarked"
      :color="bookmarked ? ColorType.ACCENT : ColorType.PRIMARY"
      :loading="loadingBookmarks"
      @click="emit('bookmark')"
    />
    <div class="separator"/>
    <icon-button
      label="Like"
      :svg-symbol-id="rating === RatingDTO.LIKE ? 'thumb-up-active' : 'thumb-up'"
      size="24px 24px"
      :aria-pressed="rating === RatingDTO.LIKE"
      :color="rating === RatingDTO.LIKE ? ColorType.ACCENT : ColorType.PRIMARY"
      flat
      :loading="loadingLike"
      @click="emit('like')"
    />
    <icon-button
      label="Dislike"
      :aria-pressed="rating === RatingDTO.DISLIKE"
      :color="rating === RatingDTO.DISLIKE ? ColorType.ACCENT : ColorType.PRIMARY"
      :svg-symbol-id="rating === RatingDTO.DISLIKE ? 'thumb-down-active' : 'thumb-down'"
      size="24px 24px"
      flat
      :loading="loadingLike"
      @click="emit('dislike')"
    />
  </div>
</template>
<style lang="scss" scoped>
.div {
  display: flex;
  width: 196px;
  height: 64px;
  padding: var(--XS) var(--S);
  justify-content: center;
  align-items: center;
  gap: var(--S);
}

.separator {
  height: var(--M);
  width: 2px;
  background-color: var(--primary-color-primary-20);
  border-radius: 2px;
  margin: var(--S);
}

</style>
