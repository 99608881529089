<script setup lang="ts">
</script>
<template>
  <div class="loader">
    <div class="circle yellow1"/>
    <div class="circle yellow2"/>
    <div class="circle yellow3"/>
    <div class="circle yellow4"/>
  </div>
</template>
<style lang="scss" scoped>
$square-size: 54px;
$gap: 12px;
.loader {
  width: calc($square-size * 2 + $gap);
  margin: auto;
  display: flex;
  gap: $gap;
  flex-wrap: wrap;
}

.circle {
  width: $square-size;
  height: $square-size;
  border-radius: 50%;
  animation: 1s infinite changeColors
}

.yellow1 {
  background-color: var(--illustration-color-illustration);
  animation-delay: 0.25s;
}

.yellow2 {
  background-color: var(--illustration-color-illustration-80);
  animation-delay: 0.5s;
}

.yellow3 {
  background-color: var(--illustration-color-illustration-40);
  animation-delay: 1s;
}

.yellow4 {
  background-color: var(--illustration-color-illustration-20);
  animation-delay: 0.75s;
}

@keyframes changeColors {
  0%, 25% {
    background-color: var(--illustration-color-illustration);
  }
  25%, 50% {
    background-color: var(--illustration-color-illustration-80);
  }
  50%, 75% {
    background-color: var(--illustration-color-illustration-40);
  }
  75%, 100% {
    background-color: var(--illustration-color-illustration-20);
  }
}
</style>
