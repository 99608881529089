<script lang="ts" setup>
import type {RecCard} from '@/ts/types/component/recs.type';
import {useUnlockStore} from '@/ts/store/unlock-store';
import type {FeedSectionTypeDTO} from '@/ts/types/dto/feed.dto';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import {SvgIllustrationFile} from '@/ts/types/component/svg-icon.type';
import {computed} from 'vue';
import {TopicDTO} from '@/ts/types/dto/topic.dto';

const props = defineProps<{
  cardData: RecCard;
}>();

const unlockStore = useUnlockStore();

const onClick = (): void => {
  if (props.cardData.section && props.cardData.section !== 'bookmarks') {
    unlockStore.startReassessTransition(
      props.cardData.section as FeedSectionTypeDTO, // TODO needed here? could be null
      props.cardData.topic,
      props.cardData.recommendation!.id.itemId,
    );
  }
};

const illustration = computed((): SvgIllustrationFile => {
  return {
    [TopicDTO.MENTAL_WELLBEING]: SvgIllustrationFile.TOPIC_MENTAL_WELLBEING,
    [TopicDTO.SLEEP]: SvgIllustrationFile.TOPIC_SLEEP,
    [TopicDTO.NUTRITION]: SvgIllustrationFile.TOPIC_NUTRITION,
    [TopicDTO.PHYSICAL_ACTIVITY]: SvgIllustrationFile.TOPIC_PHYSICAL_ACTIVITY,
  }[props.cardData.topic ?? TopicDTO.MENTAL_WELLBEING];
});

const titleLangKey = computed((): string => {
  return {
    [TopicDTO.MENTAL_WELLBEING]: 'recco_rec_card_reassess_mental_wellbeing',
    [TopicDTO.SLEEP]: 'recco_rec_card_reassess_sleep',
    [TopicDTO.NUTRITION]: 'recco_rec_card_reassess_nutrition',
    [TopicDTO.PHYSICAL_ACTIVITY]: 'recco_rec_card_reassess_physical_activity',
  }[props.cardData.topic ?? TopicDTO.MENTAL_WELLBEING];
});

</script>

<template>
  <button class="questionnaire-rec-card text-primary" @click="onClick">
    <div class="text-h3 text-weight-bold text-center full-width q-pa-xs">
      {{ $t(titleLangKey) }}
    </div>

    <svg-illustration :name="illustration"/>
  </button>
</template>

<style lang="scss" scoped>

.questionnaire-rec-card {
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--accent-color-accent-40);
  border: none;
  width: 100%;
  border-radius: var(--S);
  padding: var(--XXL) 0 0 0;
  cursor: pointer;
  &:focus,
  &:hover {
    text-decoration: underline;
  }

  @include focus-visible;
}

:deep(.svg-wrapper) {
  width: 100%;
  svg {
    width: 100%;
    height: 100%;
  }
}


</style>
