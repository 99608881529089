<script lang="ts" setup async>
import NavigationBackTemplate from '@/vue/templates/navigation-back-template.vue';
import {useApi} from '@/ts/composables/use-api';
import {getRandomId} from '@/ts/utils/pure-functions';
import RecommendationCard from '@/vue/molecules/recommendation-card/recommendation-card.vue';
import {computed} from 'vue';
import type {RecCard} from '@/ts/types/component/recs.type';
import type {RecommendationDTO} from '@/ts/types/dto/recommendation.dto';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import {SvgIllustrationFile} from '@/ts/types/component/svg-icon.type';

const api = useApi();
const recommendations = await api.recommendation.getBookmarked();

const recCards = computed((): RecCard[] => {
  return recommendations.map((recommendation: RecommendationDTO, index: number, array): RecCard => ({
    index,
    isLastInList: index === array.length - 1,
    recommendation,
    section: 'bookmarks',
  }));
});

const headlineId = `headline-${getRandomId()}`;

</script>
<template>
  <navigation-back-template>
    <main class="page-content-width main">
      <h1 :id="headlineId" class="visually-hidden">
        {{ $t('recco_bookmarks_title') }}
      </h1>
      <div v-if="recommendations.length === 0" class="no-text-wrapper">
        <p class="no-text q-mb-xl q-mt-xl">
          {{ $t('recco_bookmarks_empty_state_title') }}
        </p>
        <svg-illustration :name="SvgIllustrationFile.EMPTY"/>
      </div>
      <div v-else class="article-wrapper">
        <div class="articles" role="list" :aria-labelledby="headlineId">
          <recommendation-card
            v-for="card in recCards"
            :key="card.recommendation?.id.itemId"
            :card-data="card"
          />
        </div>
      </div>
    </main>
  </navigation-back-template>
</template>
<style lang="scss" scoped>
.main {
  margin: 0 auto;
}

.no-text-wrapper:deep(svg) {
  height: 446px;
  margin: auto;
}

.article-wrapper {
  margin: 0 auto;
}

.articles {
  display: flex;
  padding-top: 82px;
  gap: 28px;
  flex-wrap: wrap;
}

.no-text-wrapper {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.no-text {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
</style>
