<script lang="ts" setup>
import type {QuestionnaireQuestion} from '@/ts/types/component/questionnaire.type';
import RegularButton from '@/vue/atoms/regular-button.vue';
import IconButton from '@/vue/atoms/icon-button.vue';
import {ref} from 'vue';

const props = defineProps<{
  question: QuestionnaireQuestion;
  submittingInProgress: boolean;
  isInputValid: boolean;
  isLastQuestion: boolean;
}>();

const emit = defineEmits<{
  navigateBack: [];
  applyAnswer: [];
  skipAnswer: [];
}>();

const continueBtnEl = ref<{$el: HTMLElement}>();

const focusContinueBtn = (): void => {
  if (props.isInputValid) {
    continueBtnEl.value?.$el?.focus();
  }
};

defineExpose<{focusContinueBtn(): void}>({
  focusContinueBtn,
});

</script>

<template>
  <div class="bar">
    <regular-button
      ref="continueBtnEl"
      class="btn-continue"
      :label="isLastQuestion ? $t('recco_finish_button') : $t('recco_continue_button')"
      :aria-label="isLastQuestion ? $t('a11y-web-questionnaire-answer-and-finish') : $t('a11y-web-questionnaire-answer')"
      :disable="!isInputValid"
      :loading="submittingInProgress"
      flex-width
      @click="emit('applyAnswer')"
    />

    <icon-button
      v-if="question.questionCounter > 1"
      class="btn-back"
      svg-symbol-id="chevron-back"
      :label="$t('a11y-web-questionnaire-back')"
      :disabled="submittingInProgress"
      outline
      @click="emit('navigateBack')"
    />

    <icon-button
      v-if="question.isSkippable"
      class="btn-skip"
      svg-symbol-id="chevron-forward"
      :label="isLastQuestion ? $t('a11y-web-questionnaire-skip-and-finish') : $t('a11y-web-questionnaire-skip')"
      :disabled="submittingInProgress"
      outline
      @click="emit('skipAnswer')"
    />
  </div>
</template>

<style lang="scss" scoped>

.bar {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.btn-back {
  order: 1;
}

.btn-continue {
  flex: 1 0 0;
  width: 100%;
  max-width: 280px;
  order: 2;
}

.btn-skip {
  order: 3;
}

</style>
