<script lang="ts" setup async>
import {useApi} from '@/ts/composables/use-api';
import {useRoute} from 'vue-router';
import LikeDislike from '@/vue/organisms/like-dislike.vue';
import NavigationBackTemplate from '@/vue/templates/navigation-back-template.vue';
import {RatingDTO} from '@/ts/types/dto/rating.dto';
import {usePageTitle} from '@/ts/composables/use-page-title';
import {RecommendationTypeDTO} from '@/ts/types/dto/recommendation.dto';
import MediaTypeAndDuration from '@/vue/molecules/media-type-and-duration.vue';
import {useRecRatingAndBookmarking} from '@/ts/composables/use-rec-rating-and-bookmarking';
import {computed} from 'vue';
import InfoBox from '@/vue/atoms/info-box.vue';
import HlsMediaPlayer from '@/vue/molecules/hls-media-player.vue';
import {MediaType} from '@/ts/types/component/media-player.type';

const {catalogId, itemId} = useRoute().params as {catalogId: string; itemId: string};
const api = useApi();
const videoData = await api.recommendation.getVideo(catalogId as string, itemId as string);
const {updatePageTitle} = usePageTitle();
updatePageTitle({title: videoData.headline});

const {
  rating,
  updateRatingInProgress,
  putRating,
  isBookmarked,
  updateBookmarkInProgress,
  putBookmark,
} = useRecRatingAndBookmarking(RecommendationTypeDTO.Audio, itemId, catalogId);

rating.value = videoData.rating;
isBookmarked.value = videoData.bookmarked;

const description = computed(() => {
  return (videoData.description ?? '').replace(/style="/gu, 'data-style="'); // make inline styles ineffective
});

</script>
<template>
  <navigation-back-template>
    <main class="content page-content-width-small">
      <hls-media-player
        :media-type="MediaType.Video"
        :poster-dynamic-resize-url="videoData.dynamicImageResizingUrl ?? ''"
        :hls-src="videoData.videoUrl"
        :warning-before-playing="videoData.warning"
      />

      <h1 class="text-h1 q-mb-md q-mt-xl">
        {{ videoData.headline }}
      </h1>

      <media-type-and-duration
        class="q-mb-xl"
        :type="RecommendationTypeDTO.Video"
        :duration="videoData.duration"
      />

      <info-box v-if="videoData.disclaimer" :text="videoData.disclaimer"/>

      <div v-if="description" class="description" v-html="description"/> <!--eslint-disable-line vue/no-v-html-->

      <div class="row justify-center">
        <like-dislike
          class="like-dislike"
          :loading-like="updateRatingInProgress"
          :loading-bookmarks="updateBookmarkInProgress"
          :rating="rating"
          :bookmarked="isBookmarked"
          @like="putRating(rating === RatingDTO.LIKE ? RatingDTO.NOT_RATED : RatingDTO.LIKE)"
          @bookmark="putBookmark"
          @dislike="putRating(rating === RatingDTO.DISLIKE ? RatingDTO.NOT_RATED : RatingDTO.DISLIKE)"
        />
      </div>
    </main>
  </navigation-back-template>
</template>
<style lang="scss" scoped>

.hls-media-player {
  margin-top: 32px;
}

.play-btn-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  margin: 0 auto var(--XXL) auto;
}

.like-dislike {
  margin: var(--XXL) 0;
}

.description {
  border-top: 2px;
  border-top: 2px solid var(--accent-color-accent-40);
  padding-top: var(--XXL);
  margin-top: var(--XXL);
}

</style>
