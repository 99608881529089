<script lang="ts" setup>
import {useDialogPluginComponent} from 'quasar';
import {ref} from 'vue';
import {useWarningStore} from '@/ts/store/warning-store';

const props = defineProps<{
  message: string,
}>();

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits,
]);

const {dialogRef, onDialogOK} = useDialogPluginComponent();
const {confirmWarning} = useWarningStore();

const skip = ref<boolean>(false);

const onClickConfirm = (): void => {
  if (skip.value) {
    confirmWarning(props.message);
  }
  onDialogOK();
};

</script>

<template>
  <q-dialog
    ref="dialogRef"
    persistent
  >
    <q-card class="card q-pa-sm">
      <q-card-section class="items-center">
        <h1 class="text-h3 q-mb-md">
          {{ $t('recco_warning_headline') }}
        </h1>

        <div class="text-body1 q-mb-md">
          {{ message }}
        </div>

        <div class="row justify-center">
          <q-checkbox v-model="skip" :label="$t('recco_warning_skip_checkbox')"/>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          class="confirm-btn"
          color="primary"
          text-color="white"
          display-as="primary"
          :label="$t('recco_warning_confirm_button')"
          autofocus
          unelevated
          @click="onClickConfirm"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style lang="scss" scoped>
.card {
  width: 400px;
}

.confirm-btn {
  width: 100%;
}
</style>

