<script lang="ts" setup>
import {useApi} from '@/ts/composables/use-api';
import {pagesPath} from '@/ts/router/pages-path';
import RegularButton from '@/vue/atoms/regular-button.vue';
import UnlockDialog from '@/vue/molecules/unlock-dialog.vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';
import RecsRow from '@/vue/organisms/recs-row.vue';
import {usePageTitle} from '@/ts/composables/use-page-title';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import {SvgIllustrationFile} from '@/ts/types/component/svg-icon.type';

const api = useApi();
const feed = await api.me.getFeed();
const {updatePageTitle} = usePageTitle();
updatePageTitle();
const route = useRoute();
const router = useRouter();

</script>
<template>
  <main class="main page-root-node page-content-width">
    <div class="header">
      <div class="header-text">
        <strong class="text-large">
          {{ $t('recco_dashboard_welcome_back_title') }}
        </strong>
        <h1 class="text-h2">
          {{ $t('recco_dashboard_welcome_back_body') }}
        </h1>
      </div>
      <div class="potted-plant">
        <svg-illustration :name="SvgIllustrationFile.POTTED_PLANT2"/>
      </div>
    </div>
    <regular-button
      :label="$t('recco_dashboard_bookmarks_link')"
      class="bookmarks"
      :to="pagesPath.main.bookmarks"
      display-as="secondary"
    />
    <recs-row
      v-for="feedItem in feed"
      :key="feedItem.type"
      :feed-item="feedItem"
    />
    <unlock-dialog/>
  </main>
</template>
<style lang="scss" scoped>
.potted-plant {
  &:deep(svg) {
    height: 203px;
    width: auto;
  }

  margin-left: auto;
}

.header-text {
  justify-content: center;
  gap: var(--L);
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.main {
  overflow-x: hidden;
  margin: 36px auto;
}

.bookmarks {
  width: 154px;
  margin: 72px 0;
}

</style>
