<script lang="ts" setup>
import {type FeedItemDTO, FeedStateDTO} from '@/ts/types/dto/feed.dto';
import {computed, nextTick, onMounted, ref, watchEffect} from 'vue';
import {useApi} from '@/ts/composables/use-api';
import {useLoadingDataRefArr, type UseLoadingReturn} from '@/ts/composables/use-loading';
import {getRandomId} from '@/ts/utils/pure-functions';
import type {RecommendationDTO} from '@/ts/types/dto/recommendation.dto';
import RecsRowHeadline from '@/vue/molecules/recs-row/recs-row-headline.vue';
import {storeToRefs} from 'pinia';
import RecsRowList from '@/vue/molecules/recs-row/recs-row-list.vue';
import RecsRowLoading from '@/vue/molecules/recs-row/recs-row-loading.vue';
import RecsRowError from '@/vue/molecules/recs-row/recs-row-error.vue';
import {useUnlockStore} from '@/ts/store/unlock-store';
import LoadingError from '@/vue/templates/loading-error.vue';
import {UnlockTransitionState} from '@/ts/types/component/questionnaire.type';

const props = defineProps<{
  feedItem: FeedItemDTO;
}>();

const unlockStore = useUnlockStore();
const {unlockTransition} = storeToRefs(unlockStore);

const sectionEl = ref<HTMLElement>();
const labelledById = getRandomId();
const api = useApi();

const isSectionUnlocked = props.feedItem.state !== FeedStateDTO.LOCK;

if (!isSectionUnlocked && unlockTransition.value?.section === props.feedItem.type) {
  // handle edge case
  // store-state says a section should get unlocked, but server-data says that section is still locked
  // reset store state in order to avoid unlock animation
  unlockStore.$reset();
}

const fetch = (): UseLoadingReturn<RecommendationDTO[]> => {
  const hasPreloadedData = unlockTransition.value &&
    unlockTransition.value.section === props.feedItem.type &&
    unlockTransition.value.preloadedRecs;

  if (isSectionUnlocked && hasPreloadedData) {
    // use preloaded data during unlock-process
    return {
      error: ref<string | null>(null),
      loading: ref<boolean>(false),
      data: ref<RecommendationDTO[]>(unlockTransition.value!.preloadedRecs!),
      submitHandler: async(): Promise<void> => Promise.resolve(),
    };
  }

  if (isSectionUnlocked) {
    return useLoadingDataRefArr(async() => api.recommendation.getByType(props.feedItem.type));
  }

  return {
    error: ref<string | null>(null),
    loading: ref<boolean>(false),
    data: ref<RecommendationDTO[]>([]),
    submitHandler: async(): Promise<void> => Promise.resolve(),
  };
};

const {
  error,
  loading,
  data,
  submitHandler,
} = fetch();

const isUnlockTransitionInProgress = computed((): boolean => {
  return Boolean(props.feedItem?.topic && unlockTransition.value?.section === props.feedItem.type);
});

const isBusy = computed((): boolean => {
  return loading.value || isUnlockTransitionInProgress.value;
});

const err = computed(() => {
  // TODO reconsider how to handle empty list of recommendations in context of SHAD-498 and SHAD-520)
  const notLoadingOrLocked = !loading.value && isSectionUnlocked;
  const noData = notLoadingOrLocked && data.value.length === 0 ? 'No data' : null;
  return error.value ?? noData;
});

onMounted(() => {
  if (isUnlockTransitionInProgress.value) {
    sectionEl.value!.scrollIntoView({
      block: 'center',
    });
  }
});

watchEffect(() => {
  if (
      isUnlockTransitionInProgress.value &&
      sectionEl.value &&
      unlockTransition.value?.state === UnlockTransitionState.UnlockingAnimationInProgress
  ) {
    void nextTick(() => {
      sectionEl.value!.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
      sectionEl.value!.focus();
    });
  }
});

</script>
<template>
  <section
    ref="sectionEl"
    :aria-labelledby="labelledById"
    aria-live="off"
    :aria-busy="isBusy"
    :aria-atomic="true"
    tabindex="-1"
  >
    <recs-row-headline :id="labelledById" :type="feedItem.type" :show-error="Boolean(error)"/>

    <div class="row">
      <loading-error :error="err" :loading="loading">
        <template #error>
          <recs-row-error @reload="submitHandler"/>
        </template>
        <template #loader>
          <recs-row-loading/>
        </template>
        <template #default>
          <recs-row-list
            :recommendations="data"
            :feed-item="feedItem"
            :labelled-by-id="labelledById"
          />
        </template>
      </loading-error>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: var(--S) 0;
  margin-bottom: 48px;
}
</style>
