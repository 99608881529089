<script lang="ts" setup async>
import {useApi} from '@/ts/composables/use-api';
import {useRoute} from 'vue-router';
import LazyImage from '@/vue/molecules/lazy-image.vue';
import LikeDislike from '@/vue/organisms/like-dislike.vue';
import NavigationBackTemplate from '@/vue/templates/navigation-back-template.vue';
import {RatingDTO} from '@/ts/types/dto/rating.dto';
import {usePageTitle} from '@/ts/composables/use-page-title';
import {RecommendationTypeDTO} from '@/ts/types/dto/recommendation.dto';
import MediaTypeAndDuration from '@/vue/molecules/media-type-and-duration.vue';
import {useRecRatingAndBookmarking} from '@/ts/composables/use-rec-rating-and-bookmarking';
import HlsAudioPlayerFullscreen from '@/vue/molecules/hls-audio-player-fullscreen.vue';
import {computed} from 'vue';
import {MediaType} from '@/ts/types/component/media-player.type';
import HlsMediaPlayer from '@/vue/molecules/hls-media-player.vue';

const {catalogId, itemId} = useRoute().params as {catalogId: string; itemId: string};
const api = useApi();
const audioData = await api.recommendation.getAudio(catalogId as string, itemId as string);
const {updatePageTitle} = usePageTitle();
updatePageTitle({title: audioData.headline});

const {
  rating,
  updateRatingInProgress,
  putRating,
  isBookmarked,
  updateBookmarkInProgress,
  putBookmark,
} = useRecRatingAndBookmarking(RecommendationTypeDTO.Audio, itemId, catalogId);

rating.value = audioData.rating;
isBookmarked.value = audioData.bookmarked;

const description = computed(() => {
  return (audioData.description ?? '').replace(/style="/gu, 'data-style="'); // make inline styles ineffective
});

</script>
<template>
  <navigation-back-template>
    <main class="content page-content-width-small">
      <hls-media-player
        :media-type="MediaType.Audio"
        :poster-dynamic-resize-url="audioData.dynamicImageResizingUrl ?? ''"
        :hls-src="audioData.audioUrl"
        :poster-alt-text="audioData.imageAlt"
      />

      <h1 class="text-h1 q-mb-md q-mt-xl">
        {{ audioData.headline }}
      </h1>

      <media-type-and-duration
        class="q-mb-xl"
        :type="RecommendationTypeDTO.Audio"
        :duration="audioData.duration"
      />

      <h2 v-if="audioData.transcription" class="transcription-headline text-body1 text-bold text-accent">
        {{ $t('recco_audio_transcription') }}
      </h2>

      <div v-if="description" v-html="description"/> <!--eslint-disable-line vue/no-v-html-->

      <div class="row justify-center">
        <like-dislike
          class="like-dislike"
          :loading-like="updateRatingInProgress"
          :loading-bookmarks="updateBookmarkInProgress"
          :rating="rating"
          :bookmarked="isBookmarked"
          @like="putRating(rating === RatingDTO.LIKE ? RatingDTO.NOT_RATED : RatingDTO.LIKE)"
          @bookmark="putBookmark"
          @dislike="putRating(rating === RatingDTO.DISLIKE ? RatingDTO.NOT_RATED : RatingDTO.DISLIKE)"
        />
      </div>
    </main>
  </navigation-back-template>
</template>
<style lang="scss" scoped>

.hls-media-player {
  margin-top: 32px;
}

.play-btn-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transcription-headline {
  border-top: 1px solid var(--accent-color-accent-40);
  padding-top: 48px;
  margin-bottom: 48px;
}

.content {
  margin: 0 auto var(--XXL) auto;
}

.like-dislike {
  margin: var(--XXL) 0;
}

</style>
