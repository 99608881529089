<script lang="ts" setup async>
import {useApi} from '@/ts/composables/use-api';
import {useRoute} from 'vue-router';
import LazyImage from '@/vue/molecules/lazy-image.vue';
import LikeDislike from '@/vue/organisms/like-dislike.vue';
import NavigationBackTemplate from '@/vue/templates/navigation-back-template.vue';
import {RatingDTO} from '@/ts/types/dto/rating.dto';
import {usePageTitle} from '@/ts/composables/use-page-title';
import {RecommendationTypeDTO} from '@/ts/types/dto/recommendation.dto';
import MediaTypeAndDuration from '@/vue/molecules/media-type-and-duration.vue';
import HlsAudioPlayerPlain from '@/vue/molecules/hls-audio-player-plain.vue';
import {useRecRatingAndBookmarking} from '@/ts/composables/use-rec-rating-and-bookmarking';

const {catalogId, itemId} = useRoute().params as {catalogId: string; itemId: string};
const api = useApi();
const article = await api.recommendation.getArticle(catalogId as string, itemId as string);
const {updatePageTitle} = usePageTitle();
updatePageTitle({title: article.headline});

const {
  rating,
  updateRatingInProgress,
  putRating,
  isBookmarked,
  updateBookmarkInProgress,
  putBookmark,
} = useRecRatingAndBookmarking(RecommendationTypeDTO.Article, itemId, catalogId);

isBookmarked.value = article.bookmarked;
rating.value = article.rating;

</script>
<template>
  <navigation-back-template>
    <main class="content page-content-width-small">
      <div class="image-frame">
        <lazy-image
          :width="1040"
          :height="504"
          format="webp"
          :src="article.dynamicImageResizingUrl"
          loading="eager"
          :alt="article.imageAlt"
        />
      </div>

      <h1 class="text-h1 q-mb-md q-mt-xl">
        {{ article.headline }}
      </h1>

      <media-type-and-duration
        class="q-mb-xl"
        :type="RecommendationTypeDTO.Article"
        :duration="article.duration"
      />

      <div v-if="article.audioUrl" class="q-mb-xl">
        <hls-audio-player-plain :hls-src="article.audioUrl"/>
      </div>

      <p class="text-body1 text-weight-bold">
        {{ article.lead }}
      </p>

      <div data-playwright="content" v-html="article.articleBodyHtml"/> <!--eslint-disable-line vue/no-v-html-->

      <div class="row justify-center">
        <like-dislike
          class="like-dislike"
          :loading-like="updateRatingInProgress"
          :loading-bookmarks="updateBookmarkInProgress"
          :rating="rating"
          :bookmarked="isBookmarked"
          @like="putRating(rating === RatingDTO.LIKE ? RatingDTO.NOT_RATED : RatingDTO.LIKE)"
          @bookmark="putBookmark"
          @dislike="putRating(rating === RatingDTO.DISLIKE ? RatingDTO.NOT_RATED : RatingDTO.DISLIKE)"
        />
      </div>
    </main>
  </navigation-back-template>
</template>
<style lang="scss" scoped>

.image-frame {
  overflow: hidden;
  border-bottom-left-radius: 80px;
  border-top-right-radius: 80px;
  margin-top: 32px;

  @media (min-width: $breakpoint-md-min) {
    margin-left: -42px;
    margin-right: -42px;
  }

  @media (min-width: $breakpoint-lg-min) {
    margin-left: -100px;
    margin-right: -100px;
  }

}

.content {
  margin: 0 auto var(--XXL) auto;
}

.like-dislike {
  margin: var(--XXL) 0;
}

</style>
