<script lang="ts" setup>
  import {getRandomId} from '@/ts/utils/pure-functions';

  const props = defineProps<{
    disable?: boolean,
  }>();

  const emit = defineEmits<{
    click: [],
  }>();

  const onClick = (): void => {
    if (!props.disable) {
      emit('click');
    }
  };

  const randomId = getRandomId();

</script>

<template>
  <div
    class="play-button text-h4 text-white"
    :class="{'play-button--disabled': disable}"
    @click="onClick"
  >
    <q-btn
      color="accent"
      icon="play_arrow"
      :aria-labelledby="`play-label-${randomId}`"
      :disable="disable"
      @keydown.enter="onClick"
    />
    <span :id="`play-label-${randomId}`">Play</span>
  </div>
</template>

<style lang="scss" scoped>
.play-button {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: $generic-border-radius;
  padding: var(--XS) var(--S) var(--XS) var(--XS);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--S);

  &--disabled {
    cursor: not-allowed;
  }
}
</style>