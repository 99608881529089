<script setup lang="ts">
import {ref, watchEffect} from 'vue';
import {QuestionAnswerType} from '@/ts/types/dto/questionnaire.dto';
import type {QuestionnaireAnswer, QuestionnaireQuestion} from '@/ts/types/component/questionnaire.type';
import QuestionButtonBar from '@/vue/molecules/questionnaire/question-button-bar.vue';
import QuestionAnswer from '@/vue/molecules/questionnaire/question-answer.vue';
import {getRandomId} from '@/ts/utils/pure-functions';

const props = defineProps<{
  question: QuestionnaireQuestion;
  answerPreset: QuestionnaireAnswer | null;
  submittingInProgress: boolean;
  isLastQuestion: boolean;
}>();

const emit = defineEmits<{
  answerGiven: [answer: QuestionnaireAnswer];
  navigateBack: [];
}>();

const questionEl = ref<HTMLElement>();
const buttonBarEl = ref<typeof QuestionButtonBar>();
const selection = ref<number[]>([]);
const numericInput = ref<number>(0);
const isInputValid = ref<boolean>(false);
const labeledById = ref<string>('question');

watchEffect(() => {
  if (props.question.type === QuestionAnswerType.MultiChoice) {
    const hasSelection = Array.isArray(props.answerPreset?.multichoice);
    selection.value = hasSelection ? [...props.answerPreset!.multichoice!] : [];
  }

  if (props.question.type === QuestionAnswerType.Numeric) {
    numericInput.value = props.answerPreset?.numeric ?? 0;
  }

  questionEl.value?.focus();
  labeledById.value = `question-${getRandomId()}`;
});

const onApplyAnswer = (): void => {
  const {type, questionnaireId, id: questionId} = props.question;
  const answer: QuestionnaireAnswer = {
    type,
    questionId,
    questionnaireId,
  };

  if (answer.type === QuestionAnswerType.MultiChoice) {
    answer.multichoice = selection.value;
  }

  if (answer.type === QuestionAnswerType.Numeric) {
    answer.numeric = numericInput.value;
  }

  emit('answerGiven', answer);
};

const onSkipAnswer = (): void => {
  const {type, questionnaireId, id: questionId} = props.question;
  const answer: QuestionnaireAnswer = {
    type,
    questionId,
    questionnaireId,
  };
  answer.isSkipped = true;
  emit('answerGiven', answer);
};

</script>

<template>
  <main ref="questionEl" class="question page-content-width-small q-pt-sm q-pb-sm" tabindex="-1">
    <h2
      :id="labeledById"
      class="text-center q-mb-sm q-mt-xl"
    >
      {{ question.text }}
    </h2>

    <question-answer
      v-model:is-input-valid="isInputValid"
      v-model:numericInput="numericInput"
      v-model:selection="selection"
      :question="question"
      :labeled-by="labeledById"
      @enterInput="buttonBarEl?.focusContinueBtn()"
    />

    <question-button-bar
      ref="buttonBarEl"
      :question="question"
      :submitting-in-progress="submittingInProgress"
      :is-input-valid="isInputValid"
      :is-last-question="isLastQuestion"
      @applyAnswer="onApplyAnswer"
      @skipAnswer="onSkipAnswer"
      @navigateBack="emit('navigateBack')"
    />
  </main>
</template>

<style lang="scss" scoped>

.question {
  outline: none;
  margin: 0 auto;
}

</style>
