import {pagesPath} from '@/ts/router/pages-path';
import type {RouteRecordRaw} from 'vue-router';

export const devPages: RouteRecordRaw[] = [
  {
    path: pagesPath.dev.auth,
    component: async() => import('@/vue/pages/dev/dev-auth-page.vue'),
  },
  {
    path: pagesPath.dev.main,
    component: async() => import('@/vue/pages/dev/dev-main-page.vue'),
  },
  {
    path: pagesPath.dev.images,
    component: async() => import('@/vue/pages/dev/dev-images.vue'),
  },
  {
    path: pagesPath.dev.icons,
    component: async() => import('@/vue/pages/dev/dev-icons.vue'),
  },
];
