<script lang="ts" setup>
import RegularButton from '@/vue/atoms/regular-button.vue';
import LineBreaks from '@/vue/atoms/line-breaks.vue';
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {
  useRoute,
  useRouter,
  type RouteLocationNamedRaw,
} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import IconButton from '@/vue/atoms/icon-button.vue';
import {ColorType} from '@/ts/types/component/color.type';
import {storeToRefs} from 'pinia';
import {useUnlockStore} from '@/ts/store/unlock-store';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import {SvgIllustrationFile} from '@/ts/types/component/svg-icon.type';
import {
  questionnairePathSlugsForCategories, questionnairePathSlugsForSections,
  questionnairePathSlugsForTypes,
} from '@/ts/types/component/questionnaire.type';
import {TopicDTO} from '@/ts/types/dto/topic.dto';

const {t: $t} = useI18n();
const route = useRoute();
const router = useRouter();

const unlockStore = useUnlockStore();
const {isUnlockDialogOpen, isUnlockedDialogOpen, unlockTransition} = storeToRefs(unlockStore);

const to = computed((): RouteLocationNamedRaw | null => {
  if (!isUnlockDialogOpen.value) {
    return null;
  }

  return {
    name: pagesPath.unlock.questionnaireEntry,
    params: {
      id: unlockTransition.value?.questionnaireId,
      category: questionnairePathSlugsForCategories[unlockTransition.value!.topic],
      type: questionnairePathSlugsForTypes[unlockTransition.value!.type],
      section: unlockTransition.value!.section ? questionnairePathSlugsForSections[unlockTransition.value!.section] : null,
    },
  };
});

const title = computed((): string => {
  if (unlockTransition.value?.topic) {
    const titleStr = $t(`recco_dashboard_alert_${unlockTransition.value.topic}_title`);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return isUnlockedDialogOpen.value ? $t('recco_questionnaire_outro_title', {section_name: titleStr}) : titleStr;
  }
  return '';
});

const body = computed((): string => {
  if (unlockTransition.value?.topic) {
    return isUnlockedDialogOpen.value
      ? $t('recco_questionnaire_outro_body') : $t(`recco_dashboard_alert_${unlockTransition.value.topic}_body`);
  }
  return '';
});

const illustration = computed((): SvgIllustrationFile => {
  return {
    [TopicDTO.MENTAL_WELLBEING]: SvgIllustrationFile.TOPIC_MENTAL_WELLBEING,
    [TopicDTO.SLEEP]: SvgIllustrationFile.TOPIC_SLEEP,
    [TopicDTO.NUTRITION]: SvgIllustrationFile.TOPIC_NUTRITION,
    [TopicDTO.PHYSICAL_ACTIVITY]: SvgIllustrationFile.TOPIC_PHYSICAL_ACTIVITY,
  }[unlockTransition.value?.topic ?? TopicDTO.MENTAL_WELLBEING];
});

const onCancel = (): void => {
  unlockStore.$reset();
};

const onConfirmUnlocked = (): void => {
  if (unlockTransition.value) {
    unlockStore.startUnlockAnimation();
  }
};

</script>

<template>
  <q-dialog
    :model-value="Boolean(isUnlockDialogOpen || isUnlockedDialogOpen)"
    no-backdrop-dismiss
    @escape-key="onCancel"
  >
    <div class="wrapper">
      <q-card class="card">
        <q-card-section class="q-pa-none q-pt-xl text-center bg-accent-2 image-holder">
          <svg-illustration :name="illustration"/>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <h1 class="q-mt-lg q-mb-lg text-center">
            {{ title }}
          </h1>
          <line-breaks :text="body"/>
        </q-card-section>

        <q-card-actions align="center" class="text-primary q-pb-lg">
          <regular-button
            v-if="isUnlockedDialogOpen"
            :label="$t('recco_dashboard_unlock')"
            @click="onConfirmUnlocked"
          />
          <regular-button
            v-else
            :to="to"
            :label="$t('recco_dashboard_unlock')"
          />
        </q-card-actions>
      </q-card>

      <icon-button
        v-if="isUnlockDialogOpen"
        class="close-btn"
        label="close"
        svg-symbol-id="close"
        svg-view-box="0 0 36 36"
        :color="ColorType.PRIMARY"
        unelevated
        round
        dense
        @click="onCancel"
      />
    </div>
  </q-dialog>
</template>

<style lang="scss" scoped>

.wrapper {
  position: relative;
  padding-top: 24px;
}
.image-holder:deep(svg) {
  margin: auto;
}

.card {
  border-radius: 24px;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 24px;
  background-color: var(--color-light-gray);
}

:deep(.svg-wrapper svg) {
  min-width: 40%;
  height: 100%;
}

</style>

