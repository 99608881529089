import {defineStore} from 'pinia';
import {computed, ref} from 'vue';
import type {CreateQuestionnaireAnswerDTO} from '@/ts/types/dto/questionnaire.dto';
import {type QuestionnaireAnswer, QuestionnaireCategory} from '@/ts/types/component/questionnaire.type';

export const useQuestionnaireStore = defineStore('questionnaire', () => {
  const answersState = ref<Record<string, QuestionnaireAnswer[]>>({});

  const registerQuestionnaire = (questionnaireId: string): void => {
    if (!answersState.value[questionnaireId]) {
      answersState.value[questionnaireId] = [];
    }
  };

  const applyAnswer = (questionnaireId: string, answer: QuestionnaireAnswer): void => {
    const existingAnswerIndex = answersState.value[questionnaireId].findIndex(
      (existingAnswer: CreateQuestionnaireAnswerDTO) => existingAnswer.questionId === answer.questionId &&
        existingAnswer.questionnaireId === answer.questionnaireId,
    );

    if (existingAnswerIndex === -1) {
      answersState.value[questionnaireId].push(answer);
    } else if (!answer.isSkipped) {
      // override existing answer, skipped answers can not override existing answers (see comment in SHAD-409)
      answersState.value[questionnaireId].splice(existingAnswerIndex, 1, answer);
    }
  };

  const toTransferObjects = (questionnaireId: string): CreateQuestionnaireAnswerDTO[] => {
    const answerList = answersState.value[questionnaireId] ?? [];
    return answerList
      .map(({isSkipped, ...item}) => item as CreateQuestionnaireAnswerDTO);
  };

  const getAnswersByQuestionnaireId = computed(() => {
    return (questionnaireId: string): QuestionnaireAnswer[] => answersState.value[questionnaireId];
  });

  return {
    answersState,
    registerQuestionnaire,
    getAnswersByQuestionnaireId,
    applyAnswer,
    toTransferObjects,
  };
});
