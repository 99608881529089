import {
  LengthUnit,
  WeightUnit,
} from '@/ts/types/component/units-type';
import {type ColorsDTO, FontDTO} from '@/ts/types/dto/me.dto';
import type {Logger} from 'lines-logger';

export function uniqueOnly<T>(array: T[]): T[] {
  return [...new Set(array)];
}

export function uniqueOnlyById<T>(array: T[], getter: (value: T) => number | string): T[] {
  return array.filter((item, index) => array.findIndex((inner) => getter(inner) === getter(item)) === index);
}

export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms)); // eslint-disable-line no-promise-executor-return
}

export function getRandomId(): string {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return Math.floor(Math.random() * Date.now()).toString(36);
}

export function convertWeight(weight: number, fromUnit: WeightUnit, toUnit: WeightUnit): number {
  let convertedVal: number;
  if (fromUnit === toUnit) {
    convertedVal = weight;
  } else if (fromUnit === WeightUnit.Kilogram && toUnit === WeightUnit.Pound) {
    // Convert kilograms to pounds (1 kilogram = 2.20462 pounds).
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    convertedVal = weight * 2.20462;
  } else if (fromUnit === WeightUnit.Kilogram && toUnit === WeightUnit.Stone) {
    // Convert kilograms to stones (1 kilogram = 0.15747 stones).
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    convertedVal = weight * 0.15747;
  } else if (fromUnit === WeightUnit.Pound && toUnit === WeightUnit.Kilogram) {
    // Convert pounds to kilograms (1 pound = 0.45359237 kilograms).
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    convertedVal = weight * 0.45359237;
  } else if (fromUnit === WeightUnit.Stone && toUnit === WeightUnit.Kilogram) {
    // Convert stones to kilograms (1 stone = 6.35029318 kilograms).
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    convertedVal = weight * 6.35029318;
  } else {
    // other conversions not implemented yet
    convertedVal = NaN;
  }
  return convertedVal;
}

export function convertLength(value: number, fromUnit: LengthUnit, toUnit: LengthUnit): number {
  let convertedVal: number;
  if (fromUnit === toUnit) {
    convertedVal = value;
  } else if (fromUnit === LengthUnit.Inch && toUnit === LengthUnit.Centimeter) {
    // Convert inches to centimeters (1 inch = 2.54 cm).
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    convertedVal = value * 2.54;
  } else if (fromUnit === LengthUnit.Centimeter && toUnit === LengthUnit.Inch) {
    // Convert centimeters to inches (1 cm = 0.393701 inches).
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    convertedVal = value * 0.393701;
  } else if (fromUnit === LengthUnit.Foot && toUnit === LengthUnit.Centimeter) {
    // Convert feet to centimeters (1 foot = 30.48 cm).
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    convertedVal = value * 30.48;
  } else if (fromUnit === LengthUnit.Centimeter && toUnit === LengthUnit.Foot) {
    // Convert centimeters to feet (1 cm = 0.0328084 feet).
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    convertedVal = value * 0.0328084;
  } else {
    // other conversions not implemented yet
    convertedVal = NaN;
  }
  return convertedVal;
}

export function groupBy<T>(xs: T[], keyExtractor: (item: T) => string): Record<string, T[]> {
  return xs.reduce<Record<string, T[]>>((resVal, curVal) => {
    const key = keyExtractor(curVal);
    if (!resVal[key]) {
      resVal[key] = [];
    }
    resVal[key].push(curVal);
    return resVal;
  }, {});
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
export function hexToRGBA(hex: string, alpha: number): string {
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

export function generateColorSet(cssStyle: CSSStyleDeclaration, name: string, value: string): void {
  cssStyle.setProperty(name, value);
  cssStyle.setProperty(`${name}-80`, hexToRGBA(value, 0.8));
  cssStyle.setProperty(`${name}-60`, hexToRGBA(value, 0.6));
  cssStyle.setProperty(`${name}-40`, hexToRGBA(value, 0.4));
  cssStyle.setProperty(`${name}-20`, hexToRGBA(value, 0.2));
  cssStyle.setProperty(`${name}-10`, hexToRGBA(value, 0.1));
}

export function setColorScheme(cssStyle: CSSStyleDeclaration, theme: 'dark' | 'light', colors: ColorsDTO): void {
  cssStyle.setProperty(`--${theme}-primary-color-on-primary`, colors.onPrimary);
  generateColorSet(cssStyle, `--${theme}-primary-color-primary`, colors.primary);
  cssStyle.setProperty(`--${theme}-background-color-background`, colors.background);
  generateColorSet(cssStyle, `--${theme}-background-color-on-background`, colors.onBackground);
  cssStyle.setProperty(`--${theme}-accent-color-on-accent`, colors.onAccent);
  generateColorSet(cssStyle, `--${theme}-accent-color-accent`, colors.accent);
  cssStyle.setProperty(`--${theme}-illustration-color-illustration-line`, colors.illustrationOutline);
  generateColorSet(cssStyle, `--${theme}-illustration-color-illustration`, colors.illustration);
}

export function setPrimaryFontFamily(cssStyle: CSSStyleDeclaration, font: FontDTO, logger: Logger): void {
  // list from node_modules/@fontsource/[FONT_NAME]/100.css -> font-family
  // when adding new font also import its css in src/assets/sass/global-styles.scss
  const availableFonts: Record<FontDTO, string> = {
    [FontDTO.BITTER]: 'Bitter',
    [FontDTO.MONTSERRAT]: 'Montserrat',
    [FontDTO.NUNITO_SANS]: 'Nunito Sans',
    [FontDTO.POPPINS]: 'Poppins',
    [FontDTO.ROBOTO]: 'Roboto',
    [FontDTO.WORK_SANS]: 'Work Sans',
  };
  if (availableFonts[font]) {
    cssStyle.setProperty('--primary-font-family', availableFonts[font]);
  } else {
    logger.error(`Font ${font} is unavailable, available fonts: {}`, availableFonts)();
  }
}

// eslint-enable @typescript-eslint/no-magic-numbers
