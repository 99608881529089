import {defineStore} from 'pinia';
import type {TopicDTO} from '@/ts/types/dto/topic.dto';
import {
  type UnlockTransition,
  UnlockTransitionState,
  UnlockTransitionType,
} from '@/ts/types/component/questionnaire.type';
import type {FeedSectionTypeDTO} from '@/ts/types/dto/feed.dto';
import type {RecommendationResponse} from '@/ts/types/dto/recommendation.dto';

// controls unlock-dialogs on homepage and card's unlock-animation as well

interface UnlockStoreState {
  unlockTransition: UnlockTransition | null;
}

export const useUnlockStore = defineStore('unlock-store', {
  state: () => ({
    unlockTransition: null,
  } as UnlockStoreState),
  getters: {
    isUnlockDialogOpen(): boolean {
      return this.unlockTransition?.state === UnlockTransitionState.UnlockDialog &&
        Boolean(this.unlockTransition.topic);
    },

    isUnlockedDialogOpen(): boolean {
      return this.unlockTransition?.state === UnlockTransitionState.UnlockedDialog &&
        this.unlockTransition.type === UnlockTransitionType.InitialUnlock &&
        Boolean(this.unlockTransition.topic);
    },
  },
  actions: {
    startInitialUnlockTransition(
      section: FeedSectionTypeDTO | null,
      topic: TopicDTO | null | undefined,
    ): void {
      if (topic) {
        this.unlockTransition = {
          state: UnlockTransitionState.UnlockDialog,
          type: UnlockTransitionType.InitialUnlock,
          section,
          topic,
        };
      }
    },

    startReassessTransition(
      section: FeedSectionTypeDTO | null,
      topic: TopicDTO | null | undefined,
      questionnaireId: string,
    ): void {
      if (topic) {
        this.unlockTransition = {
          state: UnlockTransitionState.UnlockDialog,
          type: UnlockTransitionType.Reassess,
          section,
          topic,
          questionnaireId,
        };
      }
    },

    markAsUnlocked(preloadedRecs?: RecommendationResponse): void {
      if (this.unlockTransition?.type === UnlockTransitionType.InitialUnlock) {
        this.unlockTransition = {
          ...this.unlockTransition,
          state: UnlockTransitionState.UnlockedDialog,
          preloadedRecs,
        };
      } else {
        // Reassess will not show "unlocked"-dialog and animation
        this.finishUnlockTransition();
      }
    },

    startUnlockAnimation(): void {
      if (this.unlockTransition?.type === UnlockTransitionType.InitialUnlock && this.unlockTransition.section) {
        this.unlockTransition = {
          ...this.unlockTransition,
          state: UnlockTransitionState.UnlockingAnimationInProgress,
        };
      } else {
        // Reassess will not show animation
        // if no section set, animation isn't possible
        this.finishUnlockTransition();
      }
    },

    finishUnlockTransition(): void {
      this.unlockTransition = null;
    },
  },
});
