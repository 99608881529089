<script lang="ts" setup>
import {FeedSectionTypeDTO} from '@/ts/types/dto/feed.dto';
import {useI18n} from 'vue-i18n';
import {computed} from 'vue';
import SvgChameleon from '@/vue/atoms/svg-chameleon.vue';

const {t: $t} = useI18n();

const props = defineProps<{
  type: FeedSectionTypeDTO;
  showError?: boolean;
}>();

function injectTrans(key: string, sectionNameKey?: string): string {
  if (sectionNameKey) {
    return $t(
      `recco_dashboard_${key}`,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      {section_name: $t(`recco_dashboard_alert_${sectionNameKey}`)},
    );
  }
  return $t(`recco_dashboard_${key}`);
}

const rec: Record<FeedSectionTypeDTO, string> = {
  [FeedSectionTypeDTO.PHYSICAL_ACTIVITY_RECOMMENDATIONS]: injectTrans('recommended_for_you_topic', 'physical_activity_title'),
  [FeedSectionTypeDTO.NUTRITION_RECOMMENDATIONS]: injectTrans('recommended_for_you_topic', 'nutrition_title'),
  [FeedSectionTypeDTO.MENTAL_WELLBEING_RECOMMENDATIONS]: injectTrans('recommended_for_you_topic', 'mental_wellbeing_title'),
  [FeedSectionTypeDTO.SLEEP_RECOMMENDATIONS]: injectTrans('recommended_for_you_topic', 'sleep_title'),
  [FeedSectionTypeDTO.PREFERRED_RECOMMENDATIONS]: injectTrans('you_may_also_like'),
  [FeedSectionTypeDTO.MOST_POPULAR]: injectTrans('trending'),
  [FeedSectionTypeDTO.NEW_CONTENT]: injectTrans('new_for_you'),
  [FeedSectionTypeDTO.PHYSICAL_ACTIVITY_EXPLORE]: injectTrans('explore_topic', 'physical_activity_title'),
  [FeedSectionTypeDTO.NUTRITION_EXPLORE]: injectTrans('explore_topic', 'nutrition_title'),
  [FeedSectionTypeDTO.MENTAL_WELLBEING_EXPLORE]: injectTrans('explore_topic', 'mental_wellbeing_title'),
  [FeedSectionTypeDTO.SLEEP_EXPLORE]: injectTrans('explore_topic', 'sleep_title'),
  [FeedSectionTypeDTO.STARTING_RECOMMENDATIONS]: injectTrans('start_here'),
};

const text = computed((): string => {
  return props.showError ? $t('recco_error_loading_section') : rec[props.type];
});


</script>
<template>
  <h2 class="text-h1">
    <svg-chameleon
      v-if="showError"
      svg-symbol-id="x-mark"
      class="x-mark"
    />
    {{ text }}
  </h2>
</template>

<style lang="scss" scoped>
.text-h1 {
  display: flex;
  gap: var(--S);
  margin-bottom: 24px;
}

.x-mark {
  color: var(--color-error);
}
</style>
