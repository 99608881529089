<template>
  <error-box v-if="error" :error="error"/>
  <suspense v-else>
    <slot/>
    <template #fallback>
      <loader-animation/>
    </template>
  </suspense>
</template>
<script setup lang="ts">
import {
  onErrorCaptured,
  ref,
} from 'vue';
import LoaderAnimation from '@/vue/molecules/loader-animation.vue';
import ErrorBox from '@/vue/molecules/error-box.vue';

const error = ref('');
onErrorCaptured((err) => {
  error.value = String(err);
});
</script>
