<script lang="ts" setup>
import IconButton from '@/vue/atoms/icon-button.vue';
import type {BreadcrumbItem} from '@/ts/types/component/page.type';
import {computed} from 'vue';
import {useRoute, type RouteLocationRaw} from 'vue-router';
import {useNavOriginStore} from '@/ts/store/nav-origin-store';
import {storeToRefs} from 'pinia';
import {useI18n} from 'vue-i18n';
import {usePageStore} from '@/ts/store/page-store';
import {pagesPath} from '@/ts/router/pages-path';

const props = defineProps<{
  defaultBackTo?: BreadcrumbItem;
}>();

const {t: $t} = useI18n();
const route = useRoute();
const navOriginStore = useNavOriginStore();
const {origins} = storeToRefs(navOriginStore);
const pageStore = usePageStore();
const {currentTitle} = storeToRefs(pageStore);

const defaultBackTo = computed((): BreadcrumbItem => {
  return props.defaultBackTo ?? {
    pageTitle: $t('recco_page_title_home'),
    to: pagesPath.main.home as RouteLocationRaw,
  };
});

const backTo = computed((): BreadcrumbItem => {
  let origin;
  if (route.query.origin) {
    origin = origins.value.get(route.query.origin.toString());
  }
  return origin ?? {...defaultBackTo.value};
});

const currentPage = computed((): BreadcrumbItem => {
  return {
    pageTitle: $t(currentTitle.value.languageKey, {...currentTitle.value.data}),
    to: route,
  };
});

</script>
<template>
  <div class="page-root-node">
    <nav class="head-bg" :aria-label="$t('a11y-web-breadcrumb-nav')">
      <ol class="page-content-width breadcrumb-list">
        <li class="breadcrumb-list__item">
          <icon-button
            :label="backTo.pageTitle"
            svg-symbol-id="chevron-back"
            size="24px 24px"
            flat
            :to="backTo.to"
          />
        </li>

        <li class="breadcrumb-list__item">
          <router-link
            :to="currentPage.to"
            aria-current="page"
            class="text-h3"
            tabindex="-1"
          >
            {{ currentPage.pageTitle }}
          </router-link>
        </li>
      </ol>
    </nav>
    <slot/>
  </div>
</template>
<style lang="scss" scoped>
.head-bg {
  background: var(--accent-color-accent-20);
  padding: var(--L) 0;
}

.breadcrumb-list {
  width: 100%;
  gap: var(--M);
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__item {
    list-style-type: none;
  }
}

[aria-current="page"] {
  text-decoration: none;
  color: inherit;
  cursor: default;
}

</style>
