<script lang="ts" setup>
import {type RouteLocationNamedRaw, type RouteLocationPathRaw, type RouteLocationRaw, useRoute} from 'vue-router';
import {useNavOriginStore} from '@/ts/store/nav-origin-store';
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {usePageStore} from '@/ts/store/page-store';

const props = defineProps<{
  to: RouteLocationRaw | null;
}>();

const {t: $t} = useI18n();
const route = useRoute();
const originStore = useNavOriginStore();
const pageStore = usePageStore();

const to = computed((): RouteLocationNamedRaw | RouteLocationPathRaw | null => {
  if (!props.to) {
    return null;
  }

  const pageTitle = $t(pageStore.currentTitle.languageKey, {...pageStore.currentTitle.data});
  const originId = originStore.registerOrigin(route.fullPath, pageTitle);
  const originQuery = {origin: originId};
  let loc: RouteLocationNamedRaw | RouteLocationPathRaw;

  if (typeof props.to === 'string') {
    loc = {
      path: props.to,
      query: originQuery,
    };
  } else {
    const mergedQuery = {
      ...props.to.query ?? {},
      ...originQuery,
    };
    loc = {
      ...props.to,
      query: mergedQuery,
    };
  }

  return loc;
});

</script>

<template>
  <router-link v-if="to" :to="to">
    <slot/>
  </router-link>
  <div v-else>
    <slot/>
  </div>
</template>
