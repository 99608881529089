/*eslint-disable @typescript-eslint/member-ordering*/
import {RecommendationTypeDTO} from '@/ts/types/dto/recommendation.dto';
import {useLoadingNotification} from '@/ts/composables/use-loading';
import {RatingDTO} from '@/ts/types/dto/rating.dto';
import {ref, type Ref} from 'vue';
import {useApi} from '@/ts/composables/use-api';

interface UseRecRatingAndBookmarkingInterface {
  rating: Ref<RatingDTO>,
  updateRatingInProgress: Ref<boolean>;
  putRating(ratingData: RatingDTO): Promise<void>;
  isBookmarked: Ref<boolean>,
  updateBookmarkInProgress: Ref<boolean>,
  putBookmark(): Promise<void>;
}

export function useRecRatingAndBookmarking(
  contentType: RecommendationTypeDTO,
  itemId: string, 
  catalogId: string,
): UseRecRatingAndBookmarkingInterface {
  const api = useApi();

  const rating = ref<RatingDTO>(RatingDTO.NOT_RATED);
  const {loading: updateRatingInProgress, submitHandler: putRating} = useLoadingNotification(
    async(ratingData: RatingDTO) => api.recommendation.putRating({
      contentId: {
        itemId,
        catalogId,
      },
      contentType,
      rating: ratingData,
    }),
    (__, rat: RatingDTO): void => {
      rating.value = rat;
    },
  );

  const isBookmarked = ref<boolean>(false);
  const {
    loading: updateBookmarkInProgress,
    submitHandler: putBookmark,
  } = useLoadingNotification(
    async() => api.recommendation.putBookmark({
      contentId: {
        itemId,
        catalogId,
      },
      contentType: RecommendationTypeDTO.Article,
      bookmarked: !isBookmarked.value,
    }), (): void => {
      isBookmarked.value = !isBookmarked.value;
    },
  );

  return {
    rating,
    updateRatingInProgress,
    putRating,
    isBookmarked,
    updateBookmarkInProgress,
    putBookmark,
  };
}