<script lang="ts" setup>
import {type FeedItemDTO, FeedStateDTO} from '@/ts/types/dto/feed.dto';
import {RecommendationTypeDTO, type RecommendationDTO} from '@/ts/types/dto/recommendation.dto';
import {computed} from 'vue';
import RecommendationCard from '@/vue/molecules/recommendation-card/recommendation-card.vue';
import RecommendationCardsCarousel from '@/vue/molecules/recommendation-card/recommendation-cards-carousel.vue';
import type {RecCard} from '@/ts/types/component/recs.type';
import {NUM_OF_PLACEHOLDER_ITEMS} from '@/ts/utils/css-consts';

const props = defineProps<{
  feedItem: FeedItemDTO;
  recommendations: RecommendationDTO[];
  labelledById: string;
}>();

const recCards = computed((): RecCard[] => {
  if (props.feedItem.state === FeedStateDTO.LOCK) {
    // display placeholder-cards for locked topics
    return Array(NUM_OF_PLACEHOLDER_ITEMS)
      .fill(null)
      .map<RecCard>((_, index: number, array) => { // eslint-disable-line id-length
        return {
          index,
          isLastInList: index === array.length - 1,
          section: props.feedItem.type,
          topicLockedState: props.feedItem.state,
          topic: props.feedItem.topic,
        };
      }) as RecCard[];
  }


  // ******* TODO remove mock ***********

  // props.recommendations[1].type = RecommendationTypeDTO.Questionnaire;
  // props.recommendations[1].id.itemId = 'sdr-wellbeing';

  // ************************************


  // remove questionnaire-recs from feed-sections without topic
  const recommendations = props.feedItem.topic
      ? props.recommendations
      : props.recommendations.filter((recItem) => recItem.type !== RecommendationTypeDTO.Questionnaire);

  return recommendations.map((recommendation: RecommendationDTO, index: number, array): RecCard => {
    return {
      index,
      isLastInList: index === array.length - 1,
      recommendation,
      section: props.feedItem.type,
      topicLockedState: props.feedItem.state,
      topic: props.feedItem.topic,
    };
  });
});

</script>
<template>
  <recommendation-cards-carousel :aria-labelledby="labelledById" :total-elements="recCards.length">
    <recommendation-card
      v-for="(card, index) in recCards"
      :key="index"
      :card-data="card"
      class="card"
    />
  </recommendation-cards-carousel>
</template>

