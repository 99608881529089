<script lang="ts" setup>
import {useApi} from '@/ts/composables/use-api';
import {useUserStore} from '@/ts/store/user-store';
import {sessionStore} from '@/ts/instances/session-instance';
import {
  useRoute,
  useRouter,
  type LocationQuery,
} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import LoadingError from '@/vue/templates/loading-error.vue';
import SuspenseError from '@/vue/templates/suspense-error.vue';
import {useLoadingCb} from '@/ts/composables/use-loading';
import {useLogger} from '@/ts/composables/use-logger';
import {Language} from '@/ts/types/component/localization.type';
import {usePageStore} from '@/ts/store/page-store';

const {push, replace} = useRouter();
const userStore = useUserStore();
const pageStore = usePageStore();
const route = useRoute();
const logger = useLogger();

let query: LocationQuery = {...route.query};

// determine language
const {lang: langQuery, ...queryParamsWithoutLang} = query;
query = queryParamsWithoutLang;
let predeterminedLanguage: Language;
if (langQuery) {
  const isQueryValidLanguage = Object.values(Language).includes((langQuery as string).toLowerCase() as Language);
  if (isQueryValidLanguage) {
    predeterminedLanguage = (langQuery as string).toLowerCase() as Language;
  }
  void replace({query});
}
pageStore.determineUserLocalizationByBrowserSetting(predeterminedLanguage!);

// determine session token and fetch user-data
const api = useApi();
const {loading, error} = useLoadingCb(async() => {
  const {'session-token': sessionToken, ...queryWithoutSession} = query;
  query = queryWithoutSession;
  if (sessionToken) {
    logger.log(`Overriding localStorage session token ${sessionStore.sessionToken}`)();
    sessionStore.sessionToken = sessionToken as string;
  }
  if (sessionStore.sessionToken) {
    logger.log(`Found session token ${sessionStore.sessionToken}, check if it's valid`)();
    userStore.user = await api.me.get(); // 401 is handled in api
    if (!userStore.user!.isOnboardingQuestionnaireCompleted && !route.meta.isOnboardPage) {
      logger.log('User hasn\'t completed onboarding questionnaire redirecting to onboarding page')();
      await push({
        path: pagesPath.onboard.hello,
        query,
      });
    } else {
      await replace({query});
    }
  } else {
    logger.log('Session token is absent, redirecting to sign-in page')();
    await push({
      path: pagesPath.auth.signIn,
      query,
    });
  }
});

</script>
<template>
  <loading-error :loading="loading" :error="error">
    <suspense-error>
      <router-view/>
    </suspense-error>
  </loading-error>
</template>
