<script lang="ts" setup>

  import {computed} from 'vue';

  const props = defineProps<{
    title: string;
    currentQuestionCounter: number;
    totalNumOfQuestions: number;
  }>();

  const progress = computed(() => {
    const progressVal = props.currentQuestionCounter / props.totalNumOfQuestions;
    return Number.isNaN(progressVal) ? 0 : progressVal;
  });


</script>


<template>
  <header class="header">
    <div class="wrapper">
      <h1
        class="text-h3 q-mb-lg"
        :aria-label="$t('a11y-web-questionnaire-name', {topic: title})"
      >
        {{ title }}
      </h1>

      <q-linear-progress
        :value="progress"
        rounded
        color="accent"
        track-color="primary"
        size="5px"
        :aria-label="$t('a11y-web-questionnaire-question-progress', {n: currentQuestionCounter, total: totalNumOfQuestions})"
      />
    </div>

    <div class="text-h4 q-pt-md q-pb-lg row justify-center" aria-hidden="true">
      <span class="text-accent">{{ currentQuestionCounter }}</span>/<span>{{ totalNumOfQuestions }}</span>
    </div>
  </header>
</template>

<style lang="scss" scoped>

.header {
  border-bottom: 2px solid var(--primary-color-primary-20);
  padding-top: 40px;

  @media (min-width: $breakpoint-lg-min) {
    padding-top: 100px;
  }
}

.wrapper {
  max-width: 1189px;
  padding: 0 16px;
  margin: 0 auto;
}

:deep(.q-linear-progress__track) {
  opacity: 0.2;
}

</style>
