export interface AppStyleDTO {
  name: string;
  darkColors: ColorsDTO;
  lightColors: ColorsDTO;
  iosFont: FontDTO;
  androidFont: FontDTO;
  webFont: FontDTO;
}

export interface ColorsDTO {
  primary: string;
  onPrimary: string;
  background: string;
  onBackground: string;
  accent: string;
  onAccent: string;
  illustration: string;
  illustrationOutline: string;
}

export interface MeResponse {
  id: string;
  clientUserId: string;
  appStyle: AppStyleDTO | null;
  isOnboardingQuestionnaireCompleted: boolean;
}

export enum FontDTO {
  POPPINS = 'poppins',
  ROBOTO = 'roboto',
  MONTSERRAT = 'montserrat',
  WORK_SANS = 'work_sans',
  NUNITO_SANS = 'nunito_sans',
  BITTER = 'bitter',
}
