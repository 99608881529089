import {defineStore} from 'pinia';
import type {TopicDTO} from '@/ts/types/dto/topic.dto';
import type {BreadcrumbItem} from '@/ts/types/component/page.type';
import {getRandomId} from '@/ts/utils/pure-functions';
import {RouteLocationRaw} from 'vue-router';

interface NavOriginState {
  origins: Map<string, BreadcrumbItem>;
}

interface NavOriginActions {
  registerOrigin(path: string, title: string): string;
}

export const useNavOriginStore = defineStore<'nav-origin-store', NavOriginState, any, NavOriginActions>('nav-origin-store', {
  state: () => ({
    origins: new Map<string, BreadcrumbItem>(),
  }),
  actions: {
    registerOrigin(to: string, pageTitle: string): string {
      let existingKey;
      let newKey;
      for (const [key, value] of this.origins) {
        if (value.to === to && value.pageTitle === pageTitle) {
          existingKey = key;
          break;
        }
      }

      if (!existingKey) {
        newKey = pageTitle.toLowerCase().replace(/[^a-zA-Z0-9]/gu, '_');
        if (newKey.length === 0 || this.origins.has(newKey)) {
          newKey += getRandomId();
        }
        this.origins.set(newKey, {
          to,
          pageTitle,
        });
      }

      return (existingKey ?? newKey)!;
    },
  },
});
