<script lang="ts" setup>
import SvgChameleon from '@/vue/atoms/svg-chameleon.vue';
import {QBtn} from 'quasar';
import {computed} from 'vue';
import {ColorType} from '@/ts/types/component/color.type';

const props = defineProps<{
  displayFlat?: boolean;
  type: 'fullscreen' | 'pause' | 'play' | 'skipBackward' | 'skipForward';
}>();

const btnColor = computed((): string|undefined => {
  return props.displayFlat ? undefined : 'white'; //eslint-disable-line no-undefined
});

const btnIconColor = computed((): ColorType => {
  return (props.displayFlat ? ColorType.WHITE : ColorType.BLACK);
});

const dense = computed((): boolean => {
  return props.displayFlat || !(props.type === 'play' || props.type === 'pause');
});

const labelLangKey = computed((): string => {
  return {
    fullscreen: 'recco_media_control_fullscreen',
    pause: 'recco_media_control_pause',
    play: 'recco_media_control_play',
    skipBackward: 'recco_media_control_little_back',
    skipForward: 'recco_media_control_little_forward',
  }[props.type];
});

</script>

<template>
  <q-btn
    round
    :color="btnColor"
    :flat="props.displayFlat"
    :dense="dense"
    class="btn focus-visible"
  >
    <svg-chameleon
      v-if="type === 'play'"
      :color="btnIconColor"
      svg-symbol-id="play"
      svg-view-box="0 0 24 24"
    />
    <svg-chameleon
      v-else-if="type === 'pause'"
      :color="btnIconColor"
      svg-symbol-id="pause"
      svg-view-box="0 0 25 24"
    />
    <svg-chameleon
      v-else-if="type === 'skipForward'"
      :color="btnIconColor"
      svg-symbol-id="skip-forward"
      svg-view-box="0 0 40 40"
    />
    <svg-chameleon
      v-else-if="type === 'skipBackward'"
      :color="btnIconColor"
      svg-symbol-id="skip-backward"
      svg-view-box="0 0 40 40"
    />
    <svg-chameleon
      v-else-if="type === 'fullscreen'"
      :color="btnIconColor"
      svg-symbol-id="expand"
      svg-view-box="0 0 40 40"
    />
    <span class="visually-hidden">
      {{ $t(labelLangKey) }}
    </span>
  </q-btn>
</template>

<style lang="scss" scoped>
.btn {
  @include focus-visible;
}
</style>