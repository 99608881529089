import {pagesPath} from '@/ts/router/pages-path';
import AllAboutYouPage from '@/vue/pages/onboard/all-about-you-page.vue';
import GrowTogetherPage from '@/vue/pages/onboard/grow-together-page.vue';
import AnamnesisPage from '@/vue/pages/onboard/anamnesis-page.vue';
import AnamnesisCompletedPage from '@/vue/pages/onboard/anamnesis-completed-page.vue';
import HelloPage from '@/vue/pages/onboard/hello-page.vue';
import {type RouteRecordRaw, RouterView} from 'vue-router';
import {getMetaPageTitle} from '@/ts/router/router-factory';

export const onboardPages: RouteRecordRaw = {
  path: pagesPath.onboard.main,
  component: RouterView,
  meta: {
    isOnboardPage: true,
  },
  children: [
    {
      path: pagesPath.onboard.main,
      redirect: pagesPath.onboard.hello,
    },
    {
      path: pagesPath.onboard.hello,
      component: HelloPage,
      meta: {
        ...getMetaPageTitle('recco_page_title_onboard_step1'),
      },
    },
    {
      path: pagesPath.onboard.allAboutYou,
      component: AllAboutYouPage,
      meta: {
        ...getMetaPageTitle('recco_page_title_onboard_step2'),
      },
    },
    {
      path: pagesPath.onboard.growTogether,
      component: GrowTogetherPage,
      meta: {
        ...getMetaPageTitle('recco_page_title_onboard_step3'),
      },
    },
    {
      path: pagesPath.onboard.anamnesisStart,
      redirect: pagesPath.onboard.anamnesisQuestion.replace(':questionCounter', '1'),
    },
    {
      path: pagesPath.onboard.anamnesisQuestion,
      component: AnamnesisPage,
      meta: {
        ...getMetaPageTitle('recco_page_title_onboard_anamnesis', true),
      },
    },
    {
      path: pagesPath.onboard.anamnesisCompleted,
      component: AnamnesisCompletedPage,
      meta: {
        ...getMetaPageTitle('recco_page_title_onboard_completed'),
      },
    },
  ],
};
