<script lang="ts" setup>
import {
  ref,
  watchEffect,
} from 'vue';
import {useI18n} from 'vue-i18n';
import {usePageStore} from '@/ts/store/page-store';
import {setColorScheme, setPrimaryFontFamily} from '@/ts/utils/pure-functions';
import {useUserStore} from '@/ts/store/user-store';
import {useLogger} from '@/ts/composables/use-logger';

const pageStore = usePageStore();
const userStore = useUserStore();
const logger = useLogger();
const {locale, t: $t} = useI18n();

const pageStateNotificationsForScreenReaders = ref<string>('');

watchEffect(() => {
  const lang = pageStore.localization?.language;
  if (lang) {
    locale.value = lang;
    document.documentElement.lang = lang;
  }
  const appStyle = userStore?.user?.appStyle;
  const rootStyle = document.documentElement.style;
  if (appStyle?.lightColors) {
    setColorScheme(rootStyle, 'light', appStyle.lightColors);
  }
  if (appStyle?.darkColors) {
    setColorScheme(rootStyle, 'dark', appStyle.darkColors);
  }
  if (appStyle?.webFont) {
    setPrimaryFontFamily(rootStyle, appStyle.webFont, logger);
  }
});

watchEffect(() => {
  if (pageStore.isPageChangeInProgress) {
    pageStateNotificationsForScreenReaders.value = $t('a11y-web-announcement-enter-new-page');
    document.title = $t('recco_page_title_loading');
  } else {
    const title = $t(pageStore.currentTitle.languageKey, pageStore.currentTitle.data) || 'Recco';
    document.title = title;
    pageStateNotificationsForScreenReaders.value = $t('a11y-web-announcement-new-page-entered', {title});
  }
});

// <router-view should always exist. Otherwise
// <suspense-error><router-view will cause errors during e.g. useRouter().push('/')
</script>
<template>
  <div class="page-container text-body1">
    <div role="alert" class="visually-hidden">
      {{ pageStateNotificationsForScreenReaders }}
    </div>
    <router-view/>
  </div>
</template>
<style lang="scss">
@import '@/assets/sass/global-styles';
@import '@/assets/sass/global-variables';
</style>
<style lang="scss" scoped>
.page-container {
  display: flex;
  max-width: 100%;
  min-height: 100vh;
}
</style>
