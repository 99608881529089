<script lang="ts" setup>
import SvgChameleon from '@/vue/atoms/svg-chameleon.vue';
import {RecommendationTypeDTO} from '@/ts/types/dto/recommendation.dto';
import {computed} from 'vue';

const props = withDefaults(defineProps<{
  type: RecommendationTypeDTO;
  duration?: number|null;
  size?: 'default'|'s';
}>(), {
  duration: null,
  size: 'default',
});

const iconId = computed((): string => {
  return {
    [RecommendationTypeDTO.Article]: 'document-text',
    [RecommendationTypeDTO.Audio]: 'volume-high',
    [RecommendationTypeDTO.Video]: 'video',
    [RecommendationTypeDTO.Questionnaire]: '',
  }[props.type];
});

const labelLanguageKey = computed((): string => {
  return {
    [RecommendationTypeDTO.Article]: 'recco_media_type_articles',
    [RecommendationTypeDTO.Audio]: 'recco_media_type_audios',
    [RecommendationTypeDTO.Video]: 'recco_media_type_videos',
    [RecommendationTypeDTO.Questionnaire]: '',
  }[props.type];
});

const minutes = computed((): number => {
  //eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return props.duration ? Math.ceil(props.duration / 60) : NaN;
});

</script>

<template>
  <div class="media-type-and-duration">
    <svg-chameleon
      v-if="iconId"
      class="icon"
      :svg-symbol-id="iconId"
      svg-view-box="0 0 24 24"
    />

    <span
      v-if="labelLanguageKey"
      class="media-type-label"
      :class="(minutes ? 'media-type-label--add-separator ' : '') + `media-type-label--size-${size}`"
    >
      {{ $t(labelLanguageKey) }}
    </span>
    <span
      v-if="minutes"
      class="media-type-label"
      :class="`media-type-label--size-${size}`"
    >
      {{ $t('recco_duration_min', {val: minutes}) }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.media-type-and-duration {
  opacity: 0.6;
}

.media-type-label {
  font-weight: 600;
  font-size: toRem(15);

  &--size-s {
    font-size: toRem(13);
  }

  &--add-separator:after {
    content: " • ";
  }
}

.icon {
  position: relative;
  top: -1px;
  left: -2px;
  margin-right: 2px;
}

</style>