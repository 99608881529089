<script setup lang="ts">

import {SvgIllustrationFile} from '@/ts/types/component/svg-icon.type';
import {useLoadingDataRefNull} from '@/ts/composables/use-loading';
import LoadingError from '@/vue/templates/loading-error.vue';
import {useI18n} from 'vue-i18n';
import {computed} from 'vue';

const {t: $t} = useI18n();
const props = defineProps<{
  name: SvgIllustrationFile;
}>();
// In order to be able to style SVG, we have to load it in Html instead of using img src="icon.svg"
// Another way is to use multiple layers per color, which would result in lots of component when lots of colors

// So we load svg manually like str. This will also not increase the main js chunk

// We can't use dynamic import so all the urls should be predefined like this
// This way vite will be aware of all assets and will bundle them

// Record will force all keys of the enums to be present and resolved
// This way we won't end up in the url that's not defined

// It's also better to use import.meta.url + manual fetch
// This way we will avoid CPU consumption during string JS parsing modules like 'export default "svg ..."'
// And svg files will be still packed in raw format instead of my-icon.svg.js

const icons: Record<SvgIllustrationFile, string> = {
  [SvgIllustrationFile.ABOUT_YOU]: new URL('@/assets/img/illustrations/about-you.svg', import.meta.url).href,
  [SvgIllustrationFile.APP_CONTENT]: new URL('@/assets/img/illustrations/app-content.svg', import.meta.url).href,
  [SvgIllustrationFile.DEFAULT_IMAGE]: new URL('@/assets/img/illustrations/default-image.svg', import.meta.url).href,
  [SvgIllustrationFile.DOG]: new URL('@/assets/img/illustrations/dog.svg', import.meta.url).href,
  [SvgIllustrationFile.EMPTY]: new URL('@/assets/img/illustrations/empty.svg', import.meta.url).href,
  [SvgIllustrationFile.FLYING]: new URL('@/assets/img/illustrations/flying.svg', import.meta.url).href,
  [SvgIllustrationFile.LOGIN]: new URL('@/assets/img/illustrations/login.svg', import.meta.url).href,
  [SvgIllustrationFile.NO_CONNECTION]: new URL('@/assets/img/illustrations/no-connection.svg', import.meta.url).href,
  [SvgIllustrationFile.PEOPLE_DIGITAL]: new URL('@/assets/img/illustrations/people-digital.svg', import.meta.url).href,
  [SvgIllustrationFile.PORTRAIT]: new URL('@/assets/img/illustrations/portrait.svg', import.meta.url).href,
  [SvgIllustrationFile.PORTRAIT2]: new URL('@/assets/img/illustrations/portrait2.svg', import.meta.url).href,
  [SvgIllustrationFile.POTTED_PLANT2]: new URL('@/assets/img/illustrations/potted-plant2.svg', import.meta.url).href,
  [SvgIllustrationFile.RIDING_BIKE]: new URL('@/assets/img/illustrations/riding-bike.svg', import.meta.url).href,
  [SvgIllustrationFile.ROCKET]: new URL('@/assets/img/illustrations/rocket.svg', import.meta.url).href,
  [SvgIllustrationFile.TOPIC_PHYSICAL_ACTIVITY]: new URL('@/assets/img/illustrations/topic-physical-activity.svg', import.meta.url).href,
  [SvgIllustrationFile.TOPIC_NUTRITION]: new URL('@/assets/img/illustrations/topic-nutrition.svg', import.meta.url).href,
  [SvgIllustrationFile.TOPIC_SLEEP]: new URL('@/assets/img/illustrations/topic-sleep.svg', import.meta.url).href,
  [SvgIllustrationFile.TOPIC_MENTAL_WELLBEING]: new URL('@/assets/img/illustrations/topic-mental-wellbeing.svg', import.meta.url).href,
};

const {data, loading, error} = useLoadingDataRefNull<string>(async(): Promise<string> => {
  const result = await fetch(icons[props.name]);
  return result.text();
});
</script>

<!--eslint-disable vue/no-v-html-->
<template>
  <loading-error :loading="loading" :error="error">
    <div aria-hidden="true" class="svg-wrapper" v-html="data"/>
  </loading-error>
</template>

<style lang="scss" scoped>
.svg-wrapper :deep(svg) {
  // by default inline which added px during line-height
  display: block;
}
</style>
