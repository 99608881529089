<script lang="ts" setup>
import {computed, onMounted, ref} from 'vue';
import Hls from 'hls.js';
import MediaControlsOverlay from '@/vue/molecules/media-controls-overlay.vue';
import ButtonInitialPlay from '@/vue/atoms/button-initial-play.vue';
import {useWarning} from '@/ts/composables/use-warning';
import {MediaType} from '@/ts/types/component/media-player.type';
import LazyImage from '@/vue/molecules/lazy-image.vue';
import {useLogger} from '@/ts/composables/use-logger';

const POSTER_WIDTH_AUDIO = 1040;
const POSTER_HEIGHT_AUDIO = 504;
const POSTER_WIDTH_VIDEO = 1116;
const POSTER_HEIGHT_VIDEO = 628;
const POSTER_FORMAT = 'webp';

const props = defineProps<{
  posterDynamicResizeUrl: string;
  hlsSrc: string;
  mediaType: MediaType;
  warningBeforePlaying?: string|null;
  posterAltText?: string|null;
}>();

const videoEl = ref<HTMLMediaElement>();
const isPlaying = ref<boolean>(false);
const hasBeenStarted = ref<boolean>(false);
const canPlay = ref<boolean>(false);
const {confirmWarning} = useWarning();
const {error} = useLogger();

const isVideo = computed(() => {
  return props.mediaType === MediaType.Video;
});

const isAudio = computed(() => {
  return props.mediaType === MediaType.Audio;
});

const videoPosterUrl = computed(() => {
  const searchParams = new URLSearchParams();
  const devicePixelRatio = window.devicePixelRatio ?? 1;
  const sizeFactor = devicePixelRatio > 1 ? 1.25 : 1; // eslint-disable-line @typescript-eslint/no-magic-numbers
  const width = isVideo.value ? POSTER_WIDTH_VIDEO : POSTER_WIDTH_AUDIO;
  const height = isVideo.value ? POSTER_HEIGHT_VIDEO : POSTER_HEIGHT_AUDIO;
  searchParams.append('width', `${Math.ceil(width * sizeFactor)}`);
  searchParams.append('height', `${Math.ceil(height * sizeFactor)}`);
  searchParams.append('format', POSTER_FORMAT);
  return `${props.posterDynamicResizeUrl}?${searchParams.toString()}`;
});

let hls: Hls|null = null;
if (Hls.isSupported()) {
  hls = new Hls({autoStartLoad: false});
}

const onClickInitialPlay = async(): Promise<void> => {
  try {
    hls?.startLoad();
    if (props.warningBeforePlaying) {
      await confirmWarning(props.warningBeforePlaying);
    }
    hasBeenStarted.value = true;
    void videoEl.value?.play();
    if (isAudio.value) {
      void videoEl.value?.requestFullscreen();
    }
  } catch (err) {
    error(err as string);
  }
};
const onCanPlay = (): void => {
  canPlay.value = true;
};

const currentTime = ref<number>(0);
const duration = ref<number>(0);

const onTimeUpdate = (): void => {
  currentTime.value = videoEl.value?.currentTime ?? 0;
};

const onDurationChange = (): void => {
  duration.value = videoEl.value?.duration ?? 0;
};

const updateTime = (newTime: number): void => {
  currentTime.value = newTime;
  videoEl.value!.currentTime = newTime;
};

onMounted(() => {
  if (hls) {
    hls.loadSource(props.hlsSrc);
    hls.attachMedia(videoEl.value!);
    hls.once(Hls.Events.MANIFEST_PARSED, onCanPlay);
  } else if (videoEl.value!.canPlayType('application/vnd.apple.mpegurl')) {
    videoEl.value!.src = props.hlsSrc;
  }
});

</script>

<template>
  <div class="hls-media-player" :style="`max-height: ${isAudio ? POSTER_HEIGHT_AUDIO + 'px' : 'none'};`">
    <lazy-image
      v-if="isAudio"
      :width="POSTER_WIDTH_AUDIO"
      :height="POSTER_HEIGHT_AUDIO"
      :format="POSTER_FORMAT"
      :src="posterDynamicResizeUrl"
      loading="eager"
      :alt="posterAltText ?? ''"
    />

    <div :class="{'visually-hidden': isAudio}">
      <video
        ref="videoEl"
        class="video"
        :poster="isVideo ? videoPosterUrl : undefined"
        preload="false"
        @timeupdate="onTimeUpdate"
        @durationchange="onDurationChange"
        @playing="isPlaying = true"
        @pause="isPlaying = false"
        @ended="isPlaying = false"
        @canplay="onCanPlay"
      />
    </div>

    <div v-if="!hasBeenStarted" class="initial-play-button-overlay">
      <button-initial-play :disable="!canPlay" @click="onClickInitialPlay"/>
    </div>

    <media-controls-overlay
      v-if="hasBeenStarted"
      :media-type="mediaType"
      :is-playing="isPlaying"
      :duration="duration"
      :current-time="currentTime"
      @update:current-time="updateTime($event)"
      @click-play="videoEl!.play()"
      @click-pause="videoEl!.pause()"
      @click-fullscreen="videoEl!.requestFullscreen()"
    />
  </div>
</template>

<style lang="scss" scoped>

.hls-media-player {
  overflow: hidden;
  border-bottom-left-radius: 40px;
  border-top-right-radius: 40px;
  position: relative;

  @media (min-width: $breakpoint-md-min) {
    margin-left: -42px;
    margin-right: -42px;
    border-bottom-left-radius: 80px;
    border-top-right-radius: 80px;
  }

  @media (min-width: $breakpoint-lg-min) {
    margin-left: -100px;
    margin-right: -100px;
  }
}

.video {
  width: 100%;
  height: 100%;
  display: block;
}

.initial-play-button-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>