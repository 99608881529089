<script setup lang="ts">
import {QImg, type QImgProps} from 'quasar';
import {computed, onMounted, ref, watch} from 'vue';

const props = defineProps<{
  src: string | null; // allow fallback to stripes <template #error> when image source is null
  loading: QImgProps['loading'];
  alt?: string | null;
  width?: number;
  height?: number;
  // https://docs.directus.io/reference/files.html#custom-transformations
  // note: 'auto' is not supported in current version of directus 9.5.1
  format?: 'jpg' | 'png' | 'tiff' | 'webp' ;
}>();

const qImgEl = ref<QImg>();

const calculatedSrc = computed(() => {
  if (!props.src) {
    // quasar accepts undefined instead of null
    return undefined; // eslint-disable-line no-undefined
  }
  if (!props.width && !props.height && !props.format) {
    return props.src;
  }
  const searchParams = new URLSearchParams();
  const devicePixelRatio = window.devicePixelRatio ?? 1;
  const sizeFactor = devicePixelRatio > 1 ? 1.5 : 1; // eslint-disable-line @typescript-eslint/no-magic-numbers

  if (props.width) {
    searchParams.append('width', `${Math.ceil(props.width * sizeFactor)}`);
  }
  if (props.height) {
    searchParams.append('height', `${Math.ceil(props.height * sizeFactor)}`);
  }
  if (props.format) {
    searchParams.append('format', props.format);
  }
  return `${props.src}?${searchParams.toString()}`;
});

const setImageAlt = (): void => {
  // Set alt-attribute to img tag.
  // Unfortunately, Quasar handles alt tags with an "aria-label" and img-role on a wrapping element and
  // setting the img itself to "aria-hidden" without "alt" attribute
  // Learn more: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/img_role
  // They argue in a public bug report, that since the image itself is "aria-hidden", no alt text is needed.
  // That approach is understandable, but different from the recommended way of the WCAG.
  // WCAG requests an alt-attribute for every image (Decorative images should have an empty-sting) and a11y-analysers
  // will red-flag every image element without alt-attribute independent of the aria-hidden status.
  // With the following workaround that behaviour will be fixed:
  // add alt-attribute and an additionally presentation-role to decorative images and remove wrapper-role in the template
  // https://www.w3.org/WAI/tutorials/images/decorative/
  const imgEl: HTMLImageElement | null = (qImgEl.value?.$el as HTMLElement)?.querySelector('img') ?? null;
  if (imgEl) {
    imgEl.setAttribute('alt', props.alt ?? '');
    imgEl.setAttribute('role', 'presentation');
  }
};

onMounted(() => {
  setImageAlt();
});

watch(() => props.alt, () => {
  setImageAlt();
});

</script>
<template>
  <q-img
    ref="qImgEl"
    role=""
    :src="calculatedSrc"
    :loading="loading"
  >
    <template #error>
      <div class="error"/>
    </template>
    <template #loading>
      <q-skeleton class="loading"/>
    </template>
  </q-img>
</template>

<style lang="scss" scoped>
.loading, .error {
  width: 100%;
  height: 100%;
}

.error {
  @include background-lines(var(--primary-color-primary-20));
}
</style>
