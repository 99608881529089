import type {CreateQuestionnaireAnswerDTO, QuestionDTO} from '@/ts/types/dto/questionnaire.dto';
import {TopicDTO} from '@/ts/types/dto/topic.dto';
import {FeedSectionTypeDTO} from '@/ts/types/dto/feed.dto';
import type {RecommendationResponse} from '@/ts/types/dto/recommendation.dto';

export type QuestionnaireCategory = TopicDTO | 'onboard';

export interface Questionnaire {
  id: string;
  category: QuestionnaireCategory;
  questions: QuestionnaireQuestion[];
}

export type QuestionnaireQuestion = QuestionDTO & {
  isSkippable?: boolean;
  questionCounter: number; // use it 1-based (like the param in the path)!
};

export type QuestionnaireAnswer = CreateQuestionnaireAnswerDTO & {
  isSkipped?: boolean;
};

export enum UnlockTransitionType {
  InitialUnlock = 'unlock',
  Reassess = 'reassess',
}

export enum UnlockTransitionState {
  UnlockDialog = 'unlockDialog',
  UnlockedDialog = 'unlockedDialog',
  UnlockingAnimationInProgress = 'unlockingAnimationInProgress',
}

export interface UnlockTransition {
  section: FeedSectionTypeDTO | null;
  topic: TopicDTO;
  type: UnlockTransitionType;
  state: UnlockTransitionState;
  preloadedRecs?: RecommendationResponse;
  questionnaireId?: string;
}

export const questionnairePathSlugsForCategories: Record<TopicDTO, string> = {
  [TopicDTO.SLEEP]: 'sleep',
  [TopicDTO.NUTRITION]: 'nutrition',
  [TopicDTO.PHYSICAL_ACTIVITY]: 'physical-activity',
  [TopicDTO.MENTAL_WELLBEING]: 'mental-well-being',
};

export const questionnairePathSlugsForTypes: Record<UnlockTransitionType, string> = {
  [UnlockTransitionType.Reassess]: 'reassess',
  [UnlockTransitionType.InitialUnlock]: 'unlock',
};

export const questionnairePathSlugsForSections: Record<FeedSectionTypeDTO, string> = {
  [FeedSectionTypeDTO.PHYSICAL_ACTIVITY_RECOMMENDATIONS]: 'physical-activity-recommendations',
  [FeedSectionTypeDTO.NUTRITION_RECOMMENDATIONS]: 'nutrition-recommendations',
  [FeedSectionTypeDTO.MENTAL_WELLBEING_RECOMMENDATIONS]: 'mental-well-being-recommendations',
  [FeedSectionTypeDTO.SLEEP_RECOMMENDATIONS]: 'sleep-recommendations',
  [FeedSectionTypeDTO.PREFERRED_RECOMMENDATIONS]: 'preferred-recommendations',
  [FeedSectionTypeDTO.MOST_POPULAR]: 'most-popular-recommendations',
  [FeedSectionTypeDTO.NEW_CONTENT]: 'new-recommendations',
  [FeedSectionTypeDTO.PHYSICAL_ACTIVITY_EXPLORE]: 'physical-activity-explore-recommendations',
  [FeedSectionTypeDTO.NUTRITION_EXPLORE]: 'nutrition-explore-recommendations',
  [FeedSectionTypeDTO.MENTAL_WELLBEING_EXPLORE]: 'mental-well-being-explore-recommendations',
  [FeedSectionTypeDTO.SLEEP_EXPLORE]: 'sleep-explore-recommendations',
  [FeedSectionTypeDTO.STARTING_RECOMMENDATIONS]: 'starting-recommendations',
};
