import {useQuasar} from 'quasar';
import WarningDialog from '@/vue/molecules/warning-dialog.vue';
import {useWarningStore} from '@/ts/store/warning-store';

interface UseWarningInterface {
  confirmWarning(message: string): Promise<void>
}
export function useWarning(): UseWarningInterface {
  const $q = useQuasar();
  const {confirmedWarnings} = useWarningStore();
  const confirmWarning = async(message: string): Promise<void> => {
    if (confirmedWarnings.includes(message)) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject): void => {
      $q.dialog({
        component: WarningDialog,
        componentProps: {
          message,
        },
      }).onOk(resolve).onCancel(reject);
    });
  };

  return {
    confirmWarning,
  };
}