import type {App as VueApp} from '@vue/runtime-core';
import {moveSelection} from '@/ts/directives/move-selection';

export function addDirectives(vue: VueApp): VueApp {
  vue.directive('validity', (el: HTMLElement, binding: {value: string}) => {
    (el as HTMLInputElement).setCustomValidity(binding.value);
  });
  vue.directive('move-selection', moveSelection);
  return vue;
}
