
export type QuestionnaireResponse = QuestionDTO[];

export interface QuestionDTO {
  questionnaireId: string;
  id: string;
  text: string;
  type: QuestionAnswerType;
  multiChoice?: MultiChoiceQuestionDTO;
  multiChoiceSelectedIds?: number[];
  numeric?: NumericQuestionDTO;
  numericSelected?: number;

  /**
   @deprecated unclear why the API delivers that
   */
  index: number;
}

export interface MultiChoiceQuestionDTO {
  maxOptions: number;
  minOptions: number;
  options: MultiChoiceAnswerOptionDTO[];
}

export interface MultiChoiceAnswerOptionDTO {
  id: number;
  text: string;
}

export interface NumericQuestionDTO {
  maxValue: number;
  minValue: number;
  format: NumericQuestionFormat;
}

export interface CreateQuestionnaireAnswerDTO {
  multichoice?: number[];
  numeric?: number;
  questionnaireId: string;
  questionId: string;
  type: QuestionAnswerType;
}

// backend enum QuestionAnswerType
export enum QuestionAnswerType {
  MultiChoice = 'multichoice',
  Numeric = 'numeric',
}

// backend class Format
export enum NumericQuestionFormat {
  HumanHeight = 'human_height', // stored in cm
  HumanWeight = 'human_weight', // stored in kg
  Integer = 'integer',
  Decimal = 'decimal',
}
