import {useRoute, useRouter} from 'vue-router';
import {
  type ComputedRef,
  computed, watchEffect,
} from 'vue';
import {usePageTitle} from '@/ts/composables/use-page-title';
import {useI18n} from 'vue-i18n';
import type {TopicDTO} from '@/ts/types/dto/topic.dto';
import {questionnairePathSlugsForCategories} from '@/ts/types/component/questionnaire.type';

export function useQuestionnaireRouting(pathParamNameCounter: string = 'questionCounter'): {
  questionCounter: ComputedRef<number>;
  navigateToQuestion(navToQuestionCounter: number): void;
} {
  const {t: $t} = useI18n();
  const route = useRoute();
  const router = useRouter();
  const {updatePageTitle} = usePageTitle();

  const questionCounter = computed((): number => {
    const counterParamStr = route.params[pathParamNameCounter]?.toString() ?? '';
    const counterParamInt = parseInt(counterParamStr, 10);
    return Number.isNaN(counterParamInt) ? 1 : counterParamInt;
  });

  const topicName = computed((): string => {
    const topic: TopicDTO = (Object.entries(questionnairePathSlugsForCategories)
      .find((entry) => entry[1] === route.params.category) ?? [''])[0] as TopicDTO; // type ensured by route-guard
    return $t(`recco_dashboard_alert_${topic}_title`);
  });

  const navigateToQuestion = (navToQuestionCounter: number): void => {
    void router.push({
      params: {
        [pathParamNameCounter]: navToQuestionCounter.toString(),
      },
    });
  };

  watchEffect(() => {
    updatePageTitle({
      n: questionCounter.value, // eslint-disable-line id-length
      topic: topicName.value,
    });
  });

  return {
    questionCounter,
    navigateToQuestion,
  };
}
