export enum SvgIllustrationFile {
  ABOUT_YOU = 'ABOUT_YOU',
  APP_CONTENT = 'APP_CONTENT',
  DEFAULT_IMAGE = 'DEFAULT_IMAGE',
  DOG = 'DOG',
  EMPTY = 'EMPTY',
  FLYING = 'FLYING',
  LOGIN = 'LOGIN',
  NO_CONNECTION = 'NO_CONNECTION',
  PEOPLE_DIGITAL = 'PEOPLE_DIGITAL',
  PORTRAIT = 'PORTRAIT',
  PORTRAIT2 = 'PORTRAIT2',
  POTTED_PLANT2 = 'POTTED_PLANT2',
  RIDING_BIKE = 'RIDING_BIKE',
  ROCKET = 'ROCKET',
  TOPIC_NUTRITION = 'TOPIC_NUTRITION',
  TOPIC_MENTAL_WELLBEING = 'TOPIC_MENTAL_WELLBEING',
  TOPIC_PHYSICAL_ACTIVITY = 'TOPIC_PHYSICAL_ACTIVITY',
  TOPIC_SLEEP = 'TOPIC_SLEEP',
}
