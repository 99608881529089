<script setup lang="ts">
import {
  computed,
  ref,
  watch,
} from 'vue';

import IconButton from '@/vue/atoms/icon-button.vue';
import {useMediaWidth} from '@/ts/composables/use-media-width';
import {
  BIG_PADDING,
  CARD_WIDTH,
  NUM_OF_PLACEHOLDER_ITEMS,
  PAGE_SMALL_PADDINGS_WIDTH,
  SMALL_PADDING,
} from '@/ts/utils/css-consts';


const carouselEl = ref<HTMLElement>();

const slide = ref(0);
const width = useMediaWidth();

const gap = 29;

const props = defineProps<{
  totalElements: number;
}>();


const cardsPerSlide = computed(() => {
  const paddings = width.value < PAGE_SMALL_PADDINGS_WIDTH ? SMALL_PADDING : BIG_PADDING;

  const elements = (width.value - 2 * paddings + gap) / (CARD_WIDTH + gap);
  return Math.min(NUM_OF_PLACEHOLDER_ITEMS, Math.floor(elements));
});

const showRightArrow = computed(() => {
  return cardsPerSlide.value * (slide.value + 1) < props.totalElements;
});

watch([slide], () => {
  const targetScrollLeft = slide.value * cardsPerSlide.value * (CARD_WIDTH + gap);
  const el: HTMLElement = (carouselEl!.value!);
  el.scrollLeft = targetScrollLeft;
});

const carouselClass = computed(() => {
  return [
    'carousel',
    'non-scrollable',
  ];
});

</script>
<template>
  <div class="wrapper">
    <div v-if="slide > 0" class="hide-left">
      <icon-button
        :label="$t('a11y-web-recommendations-carousel-move-backward')"
        svg-symbol-id="chevron-back"
        class="arrow-icon"
        @click="slide--"
      />
    </div>
    <div
      ref="carouselEl"
      :class="carouselClass"
      role="list"
      tabindex="-1"
    >
      <slot/>
    </div>
    <div v-if="showRightArrow" class="hide-right">
      <icon-button
        :label="$t('a11y-web-recommendations-carousel-move-forward')"
        svg-symbol-id="forward"
        class="arrow-icon"
        @click="slide++"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.carousel {
  flex-direction: row;
  padding: $carousel-surrounding-space;
  margin: 0 -$carousel-surrounding-space;
  display: flex;
  gap: $carousel-card-gap;
  overflow-x: hidden;
  max-width: calc(100% + $carousel-surrounding-space + $carousel-surrounding-space);
  position: relative;
}

.scrollable {
  overflow-x: auto !important;
  outline: none;
}

.hide-right, .hide-left {
  display: flex;
  z-index: 2;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 100%;
}

.hide-right {
  background: var(--white-gradient-0-100, linear-gradient(270deg, var(--background-color-background) 0%, rgba(255, 255, 255, 0.00) 100%));
  right: -$carousel-surrounding-space;
}

.hide-left {
  background: var(--white-gradient-0-100, linear-gradient(90deg, var(--background-color-background) 0%, rgba(255, 255, 255, 0.00) 100%));
  left: -$carousel-surrounding-space;
}

.wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.arrow-icon {
  background-color: var(--primary-color-on-primary);
  width: 56px;
  height: 56px;
  border-radius: var(--XXS);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1.5px solid var(--primary-color-primary-10);
}

</style>

