import {getCurrentInstance} from 'vue';
import type {Logger} from 'lines-logger';
import {loggerInstance} from '@/ts/instances/logger-instance';


export function useLogger(): Logger {
  const instance = getCurrentInstance();
  // eslint-disable-next-line no-underscore-dangle
  const logName = instance?.type.__name ?? 'vue-comp';
  return loggerInstance.getLogger(logName);
}

