<script lang="ts" setup>
import {computed} from 'vue';
import RecommendationCardLockedState from '@/vue/molecules/recommendation-card/recommendation-card-locked-state.vue';
import {storeToRefs} from 'pinia';
import type {RecCard} from '@/ts/types/component/recs.type';
import {FeedStateDTO} from '@/ts/types/dto/feed.dto';
import {useUnlockStore} from '@/ts/store/unlock-store';
import {RecommendationTypeDTO} from '@/ts/types/dto/recommendation.dto';
import RecommendationCardStandard from '@/vue/molecules/recommendation-card/recommendation-card-standard.vue';
import RecommendationCardQuestionnaire from '@/vue/molecules/recommendation-card/recommendation-card-questionnaire.vue';
import {UnlockTransitionType} from '@/ts/types/component/questionnaire.type';

const props = defineProps<{
  cardData: RecCard;
}>();

const unlockStore = useUnlockStore();
const {unlockTransition} = storeToRefs(unlockStore);

const isLocked = computed((): boolean => {
  return props.cardData.topicLockedState === FeedStateDTO.LOCK ||
    Boolean(props.cardData.section) &&
    unlockTransition.value?.section === props.cardData.section &&
    unlockTransition.value?.type === UnlockTransitionType.InitialUnlock;
});

const displayStandardCard = computed((): boolean => {
  return [
    RecommendationTypeDTO.Article as string,
    RecommendationTypeDTO.Audio as string,
    RecommendationTypeDTO.Video as string,
  ].includes(props.cardData.recommendation?.type ?? '');
});

</script>
<template>
  <div role="listitem" class="recommendation-item">
    <recommendation-card-standard
      v-if="displayStandardCard"
      :card-data="cardData"
    />

    <recommendation-card-questionnaire
      v-if="cardData.recommendation?.type === RecommendationTypeDTO.Questionnaire"
      :card-data="cardData"
    />

    <recommendation-card-locked-state
      v-if="isLocked"
      :topic="cardData.topic"
      :section="cardData.section"
      :card-index="cardData.index"
      :is-last-in-list="cardData.isLastInList"
    />
  </div>
</template>
<style lang="scss" scoped>

.recommendation-item {
  position: relative;
  width: $carousel-card-width;
  min-height: $carousel-card-height;
  flex-shrink: 0;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
  border-radius: var(--S);
}

</style>
