<script lang="ts" setup async>
import type {CreateQuestionnaireAnswerDTO} from '@/ts/types/dto/questionnaire.dto';
import {useLoadingNotification} from '@/ts/composables/use-loading';
import {useRoute, useRouter} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import {useApi} from '@/ts/composables/use-api';
import {useQuestionnaireStore} from '@/ts/store/questionnaire-store';
import {storeToRefs} from 'pinia';
import {computed} from 'vue';
import {useUnlockStore} from '@/ts/store/unlock-store';
import QuestionnaireWidget from '@/vue/organisms/questionnaire-widget.vue';
import {TopicDTO} from '@/ts/types/dto/topic.dto';
import {
  UnlockTransitionState,
  UnlockTransitionType,
  questionnairePathSlugsForCategories,
  questionnairePathSlugsForSections,
  questionnairePathSlugsForTypes,
  type QuestionnaireAnswer,
} from '@/ts/types/component/questionnaire.type';
import type {FeedSectionTypeDTO} from '@/ts/types/dto/feed.dto';

const route = useRoute();
const router = useRouter();
const api = useApi();
const questionnaireStore = useQuestionnaireStore();
const unlockStore = useUnlockStore();
const {unlockTransition} = storeToRefs(unlockStore);

const id = route.params.id?.toString();

const topic: TopicDTO = Object.entries(questionnairePathSlugsForCategories)
  .find((entry) => entry[1] === route.params.category)![0] as TopicDTO; // type ensured by route-guard

const type: UnlockTransitionType = Object.entries(questionnairePathSlugsForTypes)
  .find((entry) => entry[1] === route.params.type)![0] as UnlockTransitionType; // type ensured by route-guard

const section: FeedSectionTypeDTO | null = (Object.entries(questionnairePathSlugsForSections)
  .find((entry) => entry[1] === route.params.section) ?? [null])[0] as FeedSectionTypeDTO; // type ensured by route-guard

const questionnaire = await api.questionnaire.getQuestionnaire(topic, id);
questionnaireStore.registerQuestionnaire(questionnaire.id);

const {getAnswersByQuestionnaireId} = storeToRefs(questionnaireStore);

const storeAnswers = computed((): QuestionnaireAnswer[] => getAnswersByQuestionnaireId.value(questionnaire.id));

const titleLangKey = computed((): string => {
  return {
    [TopicDTO.MENTAL_WELLBEING]: 'recco_dashboard_alert_mental_wellbeing_title',
    [TopicDTO.SLEEP]: 'recco_dashboard_alert_sleep_title',
    [TopicDTO.NUTRITION]: 'recco_dashboard_alert_nutrition_title',
    [TopicDTO.PHYSICAL_ACTIVITY]: 'recco_dashboard_alert_physical_activity_title',
  }[topic];
});

const restoreUnlockTransition = (): void => {
  if (
      !unlockTransition.value ||
      unlockTransition.value.type !== type ||
      unlockTransition.value.state !== UnlockTransitionState.UnlockDialog ||
      unlockTransition.value.topic !== topic ||
      unlockTransition.value.questionnaireId !== id ||
      unlockTransition.value.section !== section
  ) {
    // get in sync with store state
    // things can be messed-up by page-refresh or use of browser-history-buttons
    // recover store if needed
    if (type === UnlockTransitionType.InitialUnlock) {
      unlockStore.startInitialUnlockTransition(section, topic);
    } else if (type === UnlockTransitionType.Reassess) {
      unlockStore.startReassessTransition(section, topic, id);
    }
  }
};

const {submitHandler, loading} = useLoadingNotification(
    async(values: CreateQuestionnaireAnswerDTO[]) => api.questionnaire.postQuestionnaireAnswers(values),
    (): void => {
      restoreUnlockTransition(); // could get lost by page-reload or history-navigation
      void (async(): Promise<void> => {
        if (unlockTransition.value?.type === UnlockTransitionType.InitialUnlock) {
          // animation only for type "InitialUnlock" needed
          try {
            if (!unlockTransition.value.section) {
              // section may get lost by page-refresh or browser history buttons
              throw new Error('no section available');
            }
            const recs = await api.recommendation.getByType(unlockTransition.value.section);
            unlockStore.markAsUnlocked(recs);
          } catch (err) {
            unlockStore.markAsUnlocked();
          }
        } else {
          unlockStore.finishUnlockTransition();
        }

        void await router.push(pagesPath.main.home);
      })();
    },
);

</script>
<template>
  <div class="page-root-node">
    <questionnaire-widget
      v-if="questionnaire.questions.length"
      :title="$t(titleLangKey)"
      :questionnaire="questionnaire"
      :answers="storeAnswers"
      :submitting-in-progress="loading"
      @submitAnswers="submitHandler"
    />
  </div>
</template>
