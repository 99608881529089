<script lang="ts" setup>
import ErrorPageTemplate from '@/vue/templates/error-page-template.vue';
import RegularButton from '@/vue/atoms/regular-button.vue';
import {computed} from 'vue';
import {NETWORK_ERROR} from '@/ts/types';
import {useI18n} from 'vue-i18n';
import {useRouter} from 'vue-router';
import {ColorType} from '@/ts/types/component/color.type';
import SvgChameleon from '@/vue/atoms/svg-chameleon.vue';
import {SvgIllustrationFile} from '@/ts/types/component/svg-icon.type';

const {t: $t} = useI18n();

const props = defineProps<{
  error: string;
}>();
const router = useRouter();

const header = computed(() => props.error === NETWORK_ERROR ? $t('recco_error_connection_title') : $t('recco_error_generic_title'));
const paragraph = computed(() => props.error === NETWORK_ERROR ? $t('recco_error_connection_body') : $t('recco_error_generic_body'));
</script>
<template>
  <error-page-template
    v-if="!!error"
    :image="SvgIllustrationFile.NO_CONNECTION"
    :header="header"
    :paragraph="paragraph"
  >
    <regular-button
      :label="$t('recco_error_reload')"
      svg-symbol-id="reload"
      class="btn"
      @click="router.go(0)"
    >
      <svg-chameleon
        svg-symbol-id="reload"
        svg-view-box="0 0 24 24"
        :color="ColorType.WHITE"
        size="24 24"
      />
    </regular-button>
  </error-page-template>
</template>
<style lang="scss" scoped>
.btn:deep(.q-btn__content) {
  flex-direction: row-reverse;
  gap: var(--XS)
}
</style>
