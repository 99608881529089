import {usePageStore} from '@/ts/store/page-store';
import {useRoute} from 'vue-router';
import type {RouteMetaPageTitle} from '@/ts/types/component/page.type';

interface UsePageTitleInterface {
  updatePageTitle(data?: Record<string, number | string>): void;
  displayLoadingPageTitle(): void;
}

export function usePageTitle(): UsePageTitleInterface {
  const pageStore = usePageStore();
  const route = useRoute();

  const updatePageTitle = (data?: Record<string, number | string>): void => {
    const metaPageTitle = route.meta as RouteMetaPageTitle;
    pageStore.updatePageTitle(metaPageTitle.titleLanguageKey ?? '', data);
  };

  const displayLoadingPageTitle = (): void => {
    pageStore.enterNewPageWithoutDeterminedPageTitle();
  };

  return {
    updatePageTitle,
    displayLoadingPageTitle,
  };
}
